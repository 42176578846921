import React from 'react';
import { Header } from "../components/common/header";
import { ShareIcon } from "../components/common/shareicon";
import { Footer } from "../components/common/footer";
import { Banner } from "../components/home/banner";
import { Work } from "../components/home/work";
import { Feature } from "../components/home/feature";
import { Vision } from "../components/home/vision";


const Home = () => {
    return (
        <>
            <Header />
            <Banner />
            <Work />
            <Feature />
            <Vision />
            <ShareIcon/>
            <Footer />
        </>
    )

}

export default Home;
