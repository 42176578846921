import styled from "styled-components";
import { FeatureItems } from "../molecules/feature-item";
import { CenteredHeading } from "../atoms/heading/centered-heading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FullyAutonomousDashboard from "../../assets/images/Fully-Autonomous-Dashboard.svg";
import EffortlessIntegration from "../../assets/images/Effortless-Integration.svg";
import FreeTrialAvailable from "../../assets/images/Free-Trial-Available.svg";

export const Feature = () => {
  return (
    <Wrapper>
      <Container>
        <CenteredHeading largeTitle="Our Features" />
        <Slider
          className="mainSlider"
          slidesToShow={3}
          slidesToScroll={1}
          autoplay={true}
          infinite={true}
          arrows={true}
          autoplaySpeed={3000}
          responsive={[
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          <FeatureItems
            imageURL={EffortlessIntegration}
            heading="Effortless Integration"
            paragrap="Download from Chrome Webstore, add clients or integrate with CRM"
            bgColor="#F9E4E2"
          />
          <FeatureItems
            imageURL={FreeTrialAvailable}
            heading="Take a Two Month Free Trial"
            paragrap="Test stoprr with one or multiple users and watch as existing customers are highlighted"
            bgColor="#E8F8E8"
          />
          <FeatureItems
            imageURL={FullyAutonomousDashboard}
            heading="Fully Autonomous Dashboard"
            paragrap="Manage stoprr users across your team, track activity, take control"
          />
        </Slider>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #ffffff;
  padding: 80px 0;
  position: relative;
  position: relative;
  z-index: 9;

  &:before {
    content: "";
    height: 40px;
    width: 40px;
    background: #f7f7fb;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 99;
  }
`;
const Container = styled.div`
  max-width: 1320px;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 0 auto;

  .slick-prev,
  .slick-next {
    z-index: 99;
    height: 30px;
    width: 30px;
    background: #2d97ff;
    border-radius: 50%;
    padding: 0;
    right: 0px;

    &:before {
      content: "";
      height: 6px;
      width: 6px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      display: block;
      position: absolute;
      left: 9px;
      transform: rotate(-135deg);
      top: 11px;
      opacity: 1;
    }
  }

  .slick-prev {
    left: 0px;

    &:before {
      transform: rotate(45deg);
      left: 12px;
    }
  }

  .slick-slide > div {
    margin: 0 15px;
  }

  .slick-slider {
    margin: 0 -15px;
  }
`;
