// Dependencies
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Presentational components

import { Input } from "../atoms/input/input";
import { Button } from "../atoms/button/button";

import styled from "styled-components";

import { AiOutlineShareAlt } from "react-icons/ai";

import { NavLink, Link } from "react-router-dom";

//Common Components
import { Error } from "../common/message";

//Actions
import shareUrlAction from "../../store/shareUrl/action";
export const ShareIcon = () => {
  const handleToggle = () => {
    document.querySelector(".share").classList.toggle("active");
  };

  //Action
  const dispatch = useDispatch();

  // State for loader
  const [showLoader, setShowLoader] = useState(false);

  //State for contactInfo
  const [shareInfo, setShareInfo] = useState({
    to: "",
    from: "",
    from_name: "",
    company_name: "",
  });

  //State For Error Handle
  const [error, setError] = useState({
    to: "",
    from: "",
    from_name: "",
    company_name: "",
    status: "",
    responseData: "",
  });

  //Action call on share -url
  const shareUrl = () => {
    setShowLoader(true);
    let valid = true;
    var validEmail = new RegExp(
      /^([a-zA-Z0-9\+\-]+)(\.[a-zA-Z0-9\+\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/
    );

    if (!shareInfo.from_name) {
      setError((prevState) => ({
        ...prevState,
        from_name: "From Name is required",
      }));
      valid = false;
    } else if (/^[A-Za-z\s]+$/.test(shareInfo.from_name) == false) {
      setError((prevState) => ({
        ...prevState,
        from_name: "From name must be a valid.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, from_name: "" }));
    }

    if (!shareInfo.company_name) {
      setError((prevState) => ({
        ...prevState,
        company_name: "From company name is required",
      }));
      valid = false;
    } else if (/^[A-Za-z\s]+$/.test(shareInfo.company_name) == false) {
      setError((prevState) => ({
        ...prevState,
        company_name: "From Company name must be a valid.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, company_name: "" }));
    }

    if (!shareInfo.to) {
      setError((prevState) => ({ ...prevState, to: "To email is required" }));
      valid = false;
    } else if (!validEmail.test(shareInfo.to)) {
      setError((prevState) => ({
        ...prevState,
        to: "The to email must be a valid email address.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, to: "" }));
    }
    if (!shareInfo.from) {
      setError((prevState) => ({
        ...prevState,
        from: "From email is required",
      }));
      valid = false;
    } else if (!validEmail.test(shareInfo.from)) {
      setError((prevState) => ({
        ...prevState,
        from: "The from email must be a valid email address.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, from: "" }));
    }

    if (valid) {
      let responseData = dispatch(shareUrlAction.shareUrl(shareInfo));
      if (responseData) {
        responseData.then((response) => {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            setShareInfo((prevState) => ({
              ...prevState,
              to: "",
              from_name: "",
              company_name: "",
              from: "",
            }));
            setError((prevState) => ({
              ...prevState,
              to: "",
              from_name: "",
              from: "",
              company_name: "",
              status: "",
              responseData: "",
            }));
            setShowLoader(false);
          } else {
            setShowLoader(false);
            //toast.error(response?.data?.message);
            setError((prevState) => ({
              ...prevState,
              responseData: response?.data,
              status: response?.data?.status,
            }));
          }
        });
      }
    } else {
      setShowLoader(false);
    }
  };
  //Set setContactInfo state on onchange
  const handleClick = (field, value) => {
    setShareInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [field]: "",
      responseData: {
        ...prevState?.responseData,
        data: {
          ...prevState?.responseData?.data,
          [field]: "",
        },
      },
    }));
  };

  return (
    <StyledShareIcon>
      <Flex>
        <Text onClick={handleToggle}>Share With <br/> Recruiters</Text>
        <div>
          <StyledShareBtn className="pulse" onClick={handleToggle}>
            <AiOutlineShareAlt size={30} />
          </StyledShareBtn>
          <StyledForm className="share">
            <h2> Share with Recruiters </h2>
            <Input
              type="text"
              placeholder="To"
              value={shareInfo.to}
              onChange={(e) => handleClick("to", e.target.value)}
            />
            {error.to && (
              <Error
                className="error"
                error={error.to || error?.responseData?.data?.to}
              ></Error>
            )}
            <Input
              type="text"
              placeholder="From"
              value={shareInfo.from}
              onChange={(e) => handleClick("from", e.target.value)}
            />
            {error.from && (
              <Error
                className="error"
                error={error.from || error?.responseData?.data?.from}
              ></Error>
            )}
            <Input
              type="text"
              placeholder="Your Name"
              value={shareInfo.from_name}
              onChange={(e) => handleClick("from_name", e.target.value)}
            />
            {error.from_name && (
              <Error
                className="error"
                error={error.from_name || error?.responseData?.data?.from_name}
              ></Error>
            )}
            <Input
              type="text"
              placeholder="Your Company Name"
              value={shareInfo.company_name}
              onChange={(e) => handleClick("company_name", e.target.value)}
            />
            {error.company_name && (
              <Error
                className="error"
                error={
                  error.company_name || error?.responseData?.data?.company_name
                }
              ></Error>
            )}
            <div onClick={() => shareUrl()}>
              <input type="button" loader={showLoader} value="Send" />
            </div>
          </StyledForm>
        </div>
      </Flex>
    </StyledShareIcon>
  );
};
const StyledShareIcon = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 99999;

  .error {
    margin-top: -20px;
    margin-bottom: 20px;
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;
const Text = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 20px;
  position: relative;
  margin-left: 15px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    right: calc(100% - 1px);
    top: calc(50% - 5px);
    border: 5px solid transparent;
    border-right-color: #fff;
  }
`;
const StyledShareBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fe3122;
  box-shadow: 0px 3px 6px #00000029;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;

  &.pulse {
    background: #fe3122;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
`;
const StyledForm = styled.form`
  display: none;
  background: #ffffff;
  box-shadow: 0px 20px 40px #00000012;
  border-radius: 20px 20px 0px 20px;
  padding: 20px;
  z-index: 999999;
  position: absolute;
  bottom: 60px;
  left: 60px;
  &.active {
    display: block;
  }
  h2 {
    margin-top: 0;
  }
  input[type="text"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070 !important;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    font-family: "Sen", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  input[type="button"] {
    background: #fe3122 0% 0% no-repeat padding-box;
    border-radius: 24px;
    width: 260px;
    height: 48px;
    color: #ffffff;
    font-size: 14px;
    font-family: "Sen", sans-serif;
    font-weight: bold;
    border: 1px solid #fe3122 !important;
  }
`;
