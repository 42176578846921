//Dependencies
import {createStore} from 'redux';

//Reducer
import reducer from './reducer';

//Middleware
import middleware  from './middleware';

const Store = createStore(
    reducer,
    middleware
);

//Export store
export default Store;
