//Services
import StoperApi from '../../services/StoperApi'


//Define NameSpace

const NS = '@stopper/verify_email';


//Define Action Type

export const actionTypes = {
    FORGET_PASSWORD_REQUEST: `${NS}/FORGET_PASSWORD_REQUEST`,
    FORGET_PASSWORD_SUCCESS: `${NS}/FORGET_PASSWORD_SUCCESS`,
    FORGET_PASSWORD_FAILURE: `${NS}/FORGET_PASSWORD_FAILURE`,
}

const action = (type, payload) => ({ type, payload });

//Define Actions

const actions = {
    forgetPassword: (id,hash,tempParam) => {
        return async dispatch => {
            dispatch(
                action(
                    actionTypes.FORGET_PASSWORD_REQUEST,
                    {}
                )
            );

            return StoperApi
                .forgetPassword(id,hash,tempParam)
                .then(response => {
                    dispatch(action(
                        actionTypes.FORGET_PASSWORD_SUCCESS,
                        response?.data
                    ));
                    return response;
                })
                .catch(err => {
                    dispatch(action(
                        actionTypes.FORGET_PASSWORD_FAILURE,
                        {
                          error:err?.response?.data
                        }
                    ));
                    return err?.response;
                }
                );


        }
    }
}

// Export actions
export default actions;