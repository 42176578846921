import React from 'react'
 const Error =(props) =>{
    return (
        <div className={props.className} style={{color:'red'}}>{props.error}</div>
    )
}

const Success =(props) =>{
    return (
        <div className={props.className} style={{color:'green'}}>{props.success}</div>
    )
}




export {Error,Success};

