import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { CenteredHeading } from "../components/atoms/heading/centered-heading";
import { ShareIcon } from "../components/common/shareicon";
export default class TermsConditions extends Component {
  render() {
    return (
      <>
        <Header />
        <ShareIcon />
        <ContentWrapper>
          <Container>
            <CenteredHeading largeTitle="Terms & Conditions" />
            <WhiteBox>
              <p>
                <span size="2">
                  The following terms of service (these &quot;Terms of
                  Service&quot;), govern your access to and use of&nbsp;
                </span>
                <strong>Stoprr&nbsp;</strong>
                <span size="2">
                  services, including any content, functionality and services
                  offered on or through&nbsp;
                </span>
                <u>
                  <a href="https://stoprr.com/">https://stoprr.com/</a>
                </u>{" "}
                (the &quot;Site or website&quot;)
              </p>
              <p>
                <span size="2">These&nbsp;Terms of Use&nbsp;(&ldquo;</span>
                <span size="2">
                  <strong>Terms</strong>
                </span>
                <span size="2">
                  &rdquo;) state the terms and conditions governing your use of
                  and access to the Site and constitute a legally binding
                  contract between you and&nbsp;
                </span>
                Stoprr.{" "}
                <span size="2">
                  These Terms incorporate any additional terms and conditions
                  posted by&nbsp;
                </span>
                Stoprr <span size="2">through</span> the Site, or otherwise made
                available to you by Stoprr.
              </p>
              <p>
                <span size="2">The&nbsp;</span>
                <span size="2">
                  <u>Privacy Policy</u>
                </span>
                <span size="2">&nbsp;(&ldquo;</span>
                <span size="2">
                  <strong>Policy</strong>
                </span>
                <span size="2">&rdquo;) describes how&nbsp;</span>Stoprr may use
                and disclose information that we may collect about all users and
                viewers through the Site.
              </p>
              <p>
                PLEASE REVIEW THESE TERMS AND POLICY CAREFULLY. YOUR ACCESS TO
                AND/OR USE OF THE SITE CONSTITUTES YOUR ACCEPTANCE OF, AND
                CONSENT TO BE BOUND BY, THESE TERMS AND POLICY. IF YOU DO NOT
                ACCEPT AND CONSENT TO BE BOUND BY THESE TERMS AND POLICY, THEN
                YOU MUST NOT USE OR ACCESS THE SITE.
              </p>
              <p>
                We may change these Terms and/or Policy from time to time by
                notifying you of such changes by any reasonable means, including
                by posting revised Terms or Policy through the Site. Any such
                changes will not apply to any dispute between you and us arising
                prior to the date on which we posted the revised Terms or Policy
                incorporating such changes, or otherwise notified you of such
                changes.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <strong>1. DEFINITIONS</strong>
              </p>
              <ol type="a">
                <li>
                  <p>
                    <span size="2">
                      The term &quot;us&quot; or &quot;we&quot; or
                      &quot;our&quot; refers to&nbsp;
                    </span>
                    Stoprr<span size="2">, which operates the&nbsp;</span>{" "}
                    <u>
                      <a href="https://stoprr-frontend.hestawork.com/">
                        https://stoprr.com/
                      </a>
                    </u>
                    website (the &quot;Site&quot; or &ldquo;Service&rdquo;).
                  </p>
                </li>
                <li>
                  <p>
                    The term &ldquo;User&rdquo; refers to the individual,
                    company, business organization, or other legal entity using
                    the Services and/or contributing Content to them. The
                    Content the User contributes, submits, transmits and/or
                    posts to or through the Site shall be referred to variously
                    as &ldquo;your Content&rdquo;, &ldquo;Content of
                    yours&rdquo;, and/or &ldquo;Content you submit.&rdquo;;
                  </p>
                </li>
              </ol>
              <p>
                All of the above definitions can be used in the singular and
                plural form (if applies).
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">
                  <strong>2.</strong>
                </span>
                <strong>ACCEPTANCE OF TERMS</strong>
              </p>
              <p>
                <span size="2">
                  These Terms of Use constitute a legally binding agreement made
                  between you, whether personally or on behalf of an
                  entity&nbsp;
                </span>
                <span size="2">
                  <strong>Stoprr,&nbsp;</strong>
                </span>
                <span size="2">concerning your access to and use of&nbsp;</span>
                <u>
                  <a href="https://stoprr-frontend.hestawork.com/">
                    https://stoprr.com/
                  </a>
                  &nbsp;
                </u>
                <span size="2">
                  You agree that by accessing the Site, you have read,
                  understood, and agree to be bound by the terms and conditions
                  and Privacy Policy incorporated.&nbsp;
                </span>
                <span size="2">
                  <strong>
                    IF YOU DO NOT AGREE WITH ALL OF THESE YOU, THEN YOU ARE
                    EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
                    DISCONTINUE USE IMMEDIATELY.
                  </strong>
                </span>
                <span size="2">&nbsp;</span>
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <strong>3.USAGE/ ELIGIBILITY</strong>
              </p>
              <p>
                <span size="2">
                  You will use this site in a manner consistent with any, and
                  all, applicable laws, legislation, rules and regulations. If
                  you violate any restrictions in these terms, you agree to
                  indemnify&nbsp;
                </span>
                Stoprr{" "}
                <span size="2">
                  for any losses, costs or damages, including reasonable legal
                  fees, incurred by&nbsp;
                </span>
                Stoprr{" "}
                <span size="2">
                  in relation to, or arising out of, such a breach. The legal
                  age for use of the services shall be 18 years and above.&nbsp;
                </span>
                Stoprr reserves the right to terminate any use of the service
                that doesn&rsquo;t comply with usage requirement. We will also
                not be liable to you for any damage that might arise from
                person&rsquo;s less than 18 who is found to use the services.
              </p>
              <p>
                <strong>4.TERMS OF SERVICE</strong>
              </p>
              <p>
                <span size="2">
                  <strong>Creating an Account.&nbsp;</strong>
                </span>
                <span size="2">
                  &nbsp;To use and enjoy the services we provide, we require you
                  to register an account with us. Your Account is for your
                  individual personal use only, and you may not authorize others
                  to use your Account for any purpose. In creating your Account,
                  you certify that all information you provide is complete and
                  accurate. You agree to update your information when required
                  or requested, and you further agree not to use another
                  person&rsquo;s account without permission. You are responsible
                  for maintaining the confidentiality of, and restricting access
                  to your Account and password, and you agree to accept sole
                  responsibility for all activities that occur under your
                  Account or password. You agree to contact our customer service
                  department immediately of any breach of security or
                  unauthorized use of your Account or any violation of these
                  Terms by others of which you are aware. You agree that we
                  shall have no liability for any losses, damages, liabilities
                  or expenses you may incur due to any unauthorized use of your
                  Account, and you agree to indemnify us and hold us harmless
                  for any such unauthorized use
                </span>
                <span size="2">.&nbsp;</span>
                <span size="2">
                  We reserve the right to create accounts for quality control
                  and administrative purposes. Such accounts may be publicly
                  viewable
                </span>
                <span size="1">.</span>
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">
                  <strong>User&rsquo;s Responsibilities.&nbsp;</strong>
                </span>
                By using the Website, the User undertakes in particular to:
              </p>
              <ul>
                <li>
                  <p>
                    not provide any unlawful content, including unlawful content
                    that may adversely affect the security of the Website or
                    harm other Users.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    not violate any law, contract, intellectual property or
                    other third party right or commit a tort, and that you are
                    solely responsible for your conduct, while accessing or
                    using the Sites.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    abide by these Terms and will not: Engage in any harassing,
                    threatening, intimidating, predatory or stalking
                    conduct;&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      not use or attempt to use another user&rsquo;s account
                      without authorization from such user and&nbsp;
                    </span>
                    Stoprr.
                  </p>
                </li>
                <li>
                  <p>
                    not use the Sites in any manner that could interfere with,
                    disrupt, negatively affect or inhibit other users from fully
                    enjoying the Sites or that could damage, disable, overburden
                    or impair the functioning of the Sites in any manner;
                  </p>
                </li>
                <li>
                  <p>
                    not do anything that might discover source code or bypass or
                    circumvent measures employed to prevent or limit access to
                    any Content, area or code of the Sites;&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    not access any feature or area of the Sites that you are not
                    authorized to access.
                  </p>
                </li>
                <li>
                  <p>
                    not use any robot, spider, crawler, scraper, script, browser
                    extension, offline reader or other automated means or
                    interface not authorized by us to access the Sites, extract
                    data or otherwise interfere with or modify the rendering of
                    Site pages or functionality.
                  </p>
                </li>
                <li>
                  <p>
                    not to solicit information from users for illegal
                    activities, to cause harm to people or property, or to scam
                    others.&nbsp;
                  </p>
                </li>
              </ul>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">5.</span>
                <strong>EXCLUSION OF LIABILITY FOR EXTERNAL LINKS</strong>
              </p>
              <p>
                <span size="2">The</span>
                <span size="2">
                  <strong>&nbsp;Site&nbsp;</strong>
                </span>
                <span size="2">
                  may make available or provide links to third party website,
                  content, services, or information (&ldquo;Third Party
                  Materials&rdquo;). Stoprr does not control, and is not
                  responsible for, any Third-Party Materials and the
                  availability of any Third-Party Materials on the website does
                  not imply endorsement of, or affiliation with the provider of
                  Third-Party Materials. Your use of Third-Party Materials is at
                  your own risk
                </span>
                <strong>.</strong>
              </p>
              <p>
                <span size="2">6.</span>
                <strong>INTELLECTUAL PROPERTY</strong>
              </p>
              <p>
                Unless otherwise indicated, the website is our proprietary
                property and all source code, databases, functionality,
                software, website designs, audio, video, text, photographs, and
                graphics on the Site (collectively, the &ldquo;Content&rdquo;)
                and the trademarks, service marks, and logos contained therein
                (the &ldquo;Marks&rdquo;) are owned or controlled by us or
                licensed to us, and are protected by copyright and trademark
                laws and various other intellectual property rights and unfair
                competition laws of the United States, foreign jurisdictions,
                and international conventions. The Content and the Marks are
                provided on the Site &ldquo;AS IS&rdquo; for your information
                and personal use only. Except as expressly provided in these
                Terms of Use, no part of the Site and no Content or Marks may be
                copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted,
                distributed, licensed, or otherwise exploited for any commercial
                purpose whatsoever, without our express prior written
                permission.
              </p>
              <p>
                Provided that you are eligible to use the website, you are
                granted a limited license to access and use the Site and to
                download or print a copy of any portion of the Content to which
                you have properly gained access solely for your personal,
                non-commercial use. We reserve all rights not expressly granted
                to you in and to the Site, the Content, and the Marks.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <strong>8.USER LICENSES</strong>
              </p>
              <p>
                <strong>
                  You may access and use the Website and Services only in
                  accordance with any instruction manuals, user guides and other
                  documentation as made available by Stoprr from time to time
                  (&ldquo;Documentation&rdquo;).
                </strong>
              </p>
              <p>
                This is the grant of a license, not a transfer of title, and
                under this license you may not:
              </p>
              <ol type="a">
                <ol type="i">
                  <li>
                    <p>modify or copy the materials;</p>
                  </li>
                  <li>
                    <p>
                      <span size="2">
                        attempt to decompile or reverse engineer any software
                        contained on&nbsp;
                      </span>
                      Stoprr website;
                    </p>
                  </li>
                  <li>
                    <p>
                      remove any copyright or other proprietary notations from
                      the materials; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Transfer the materials to another person or
                      &quot;mirror&quot; the materials on any other server.
                    </p>
                  </li>
                </ol>
              </ol>
              <p>
                <span size="2">
                  This license shall automatically terminate if you violate any
                  of these restrictions and may be terminated by&nbsp;
                </span>
                Stoprr at any time. Upon terminating your viewing of these
                materials or upon the termination of this license, you must
                destroy any downloaded materials in your possession whether in
                electronic or printed format.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">9.</span>
                <strong>
                  ASSIGNMENT OF THE CONTRACTUAL RIGHTS AND OBLIGATIONS
                </strong>
              </p>
              <p>
                As users of the platform, you agree and acknowledge that we may
                exercise at our sole discretion and we reserve the right to
                freely assign and/or transfer any or all of the rights and
                obligations described in this Agreement in connection to
                providing the service to you. You may not assign any of your
                rights and duties without our prior consent. Notwithstanding the
                foregoing provisions, this agreement shall be binding upon and
                inure to the benefit of the parties hereto and their respective
                successors and assigns.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">10</span>
                <strong>LIMITATION OF LIABILITY</strong>
              </p>
              <p>
                To the extent permitted under applicable law, under no
                circumstances shall we, our officers, directors, employees,
                parents, affiliates, successors, assigns, or licensors be liable
                to you or any other third party for any indirect, special,
                incidental, or consequential, exemplary or punitive damages of
                any type including, without limitation, damages for loss of
                goodwill, service interruption, computer failure or malfunction,
                loss of business profits, loss of data or business information,
                loss of additional software or computer configurations or costs
                of procurement of substitute goods or services, damages arising
                in connection with any use of the website or any and all other
                commercial damages or losses, arising out of or in connection
                with these terms. Notwithstanding anything to the contrary
                contained herein, in no event shall our total liability
                (including our officers, directors, employees, parents, and
                affiliates) for any claim arising out of or related to these
                terms, to the fullest extent possible under applicable law,
                exceed the amount paid if any, by you for the use of the
                services.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">11.</span>
                <strong>COMPLAINT PROCEDURE</strong>
              </p>
              <p>
                We have designed our service to be seamless and easily
                accessible. Should you have any complaint when using the
                platform, feel free to contact us. We will attend to your query
                immediately &ndash; no longer than 5 business days from the day
                we receive it.
              </p>
              <p>
                <strong>12.DELETING THE ACCOUNT</strong>
              </p>
              <p>
                <span size="2">
                  You may terminate these Terms and the license granted to you
                  hereunder at any time&nbsp;
                </span>
                by contacting us to delete the account. Deleting an account is
                tantamount to terminating the agreement. Any problems caused by
                account deletion, such as non-performance of User-to-User
                agreements, are the sole responsibility of the User requesting
                deletion.
              </p>
              <p>
                Stoprr{" "}
                <span size="2">
                  without prejudice to any other rights we may have, may
                  terminate these Terms and the license granted to you hereunder
                  automatically without notice, if you fail to comply with or
                  breach any provision of these Terms. In no event will we be
                  liable for the suspension, removal of or disabling of your
                  access to the site or services or to any feature available
                  therein.&nbsp;
                </span>
              </p>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">13.</span>
                <span size="2">
                  <strong>INDEMNIFICATION.</strong>
                </span>
                <span size="2">&nbsp;</span>
              </p>
              <p>
                <span size="2">
                  To the fullest extent permitted by applicable law, you agree
                  to indemnify, defend and hold harmless&nbsp;
                </span>
                Stoprr,{" "}
                <span size="2">
                  and our respective past, present and future employees,
                  officers, directors, contractors, consultants, equity holders,
                  suppliers, vendors, service providers, parent companies,
                  subsidiaries, affiliates, agents, representatives,
                  predecessors, successors and assigns (individually and
                  collectively, the Stoprr Parties&rdquo;), from and against all
                  actual or alleged Stoprr Party or third party claims, damages,
                  awards, judgments, losses, liabilities, obligations,
                  penalties, interest, fees, expenses (including, without
                  limitation, attorneys&rsquo; fees and expenses) and costs
                  (including, without limitation, court costs, costs of
                  settlement and costs of pursuing indemnification and
                  insurance), of every kind and nature whatsoever, whether known
                  or unknown, foreseen or unforeseen, matured or unmatured, or
                  suspected or unsuspected, in law or equity, whether in tort,
                  contract or otherwise (collectively, &ldquo;Claims&rdquo;),
                  including, but not limited to, damages to property or personal
                  injury, that are caused by, arise out of or are related to (a)
                  your use or misuse of the Sites, Content or Services, (b) any
                  Feedback you provide, (c) your violation of these Terms, (d)
                  your violation of the rights of another, (e) any third
                  party&rsquo;s use or misuse of the Site or Services provided
                  to you and (f) any User Content you create, post, share or
                  store on or through the Site or our pages or feeds on third
                  party social media platforms. You agree to promptly
                  notify&nbsp;
                </span>
                Stoprr{" "}
                <span size="2">
                  of any third-party Claims and cooperate with the&nbsp;
                </span>
                Stoprr{" "}
                <span size="2">
                  Parties in defending such Claims. You further agree that
                  the&nbsp;
                </span>
                Stoprr{" "}
                <span size="2">
                  Parties shall have control of the defense or settlement of any
                  third-party Claims. This indemnity is in addition to, and not
                  in lieu of, any other indemnities set forth in a written
                  agreement between you and&nbsp;
                </span>
                Stoprr.
              </p>
              <p>
                <strong>14.PROHIBITED USES</strong>
              </p>
              <p>
                You may not access or use the Site for any purpose other than
                that for which we make the available. The site may not be used
                in connection with any commercial endeavors except those that
                are specifically endorsed or approved by us.
              </p>
              <p>As a user of the Site, you agree not to:</p>
              <ol>
                <li>
                  <p>
                    Make any unauthorized use of the Site, including collecting
                    usernames and/or email addresses of users by electronic or
                    other means for the purpose of sending unsolicited email, or
                    creating user accounts by automated means or under false
                    pretenses.
                  </p>
                </li>
                <li>
                  <p>
                    Trick, defraud, or mislead us and other users, especially in
                    any attempt to learn sensitive account information such as
                    (but not limited to) user passwords.
                  </p>
                </li>
                <li>
                  <p>
                    Make improper use of our support services or submit false
                    reports of abuse or misconduct.
                  </p>
                </li>
                <li>
                  <p>
                    Circumvent, disable, or otherwise interfere with
                    security-related features of the Site, including features
                    that prevent or restrict the use or copying of any Content
                    or enforce limitations on the use of the Site and/or the
                    Content contained therein.
                  </p>
                </li>
                <li>
                  <p>
                    Engage in any automated use of the system, such as using
                    scripts to send comments or messages, or using any data
                    mining, robots, or similar data gathering and extraction
                    tools.
                  </p>
                </li>
                <li>
                  <p>
                    Attempt to impersonate another user or person or use the
                    username of another user.
                  </p>
                </li>
                <li>
                  <p>
                    Use any information obtained from the Site in order to
                    harass, abuse, or harm another person.
                  </p>
                </li>
                <li>
                  <p>
                    Decipher, decompile, disassemble, or reverse engineer any of
                    the software comprising or in any way making up a part of
                    the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Attempt to bypass any measures of the Site designed to
                    prevent or restrict access to the Site, or any portion of
                    the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Interfere with, disrupt, or create an undue burden of the
                    Site or the networks or services connected to the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Harass, annoy, intimidate, or threaten any of our employees
                    or agents engaged in providing any portion of the Site to
                    you.
                  </p>
                </li>
                <li>
                  <p>
                    Delete the copyright or other proprietary rights notice from
                    any Content.
                  </p>
                </li>
                <li>
                  <p>
                    Copy or adapt the Site&rsquo;s software, including but not
                    limited to Flash, PHP, HTML, JavaScript, or other code.
                  </p>
                </li>
                <li>
                  <p>
                    Upload or transmit (or attempt to upload or to transmit)
                    viruses, Trojan horses, or other material, including
                    excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any
                    parties&rsquo; functions, operation, or maintenance of the
                    Site.
                  </p>
                </li>
                <li>
                  <p>
                    Disparage, tarnish, or otherwise harm, in our opinion, us
                    and/or the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Use the Site in a manner inconsistent with any applicable
                    laws or regulations.
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">Use content without proper attribution</span>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    <span size="2">
                      Use content in a fashion that does not comply with the
                      content&rsquo;s specific licensing
                    </span>
                    .
                  </p>
                </li>
              </ol>
              <p>
                <br />
                &nbsp;
              </p>
              <p>
                <span size="2">15.</span>
                <span size="2">
                  <strong>DISCLAIMERS</strong>
                </span>
                <span size="2">.&nbsp;</span>
              </p>
              <p>
                <span size="2">
                  Your access to and use of the services and content provided on
                  https://stoprr-frontend.hestawork.com/ are at&nbsp;
                </span>
                <span size="2">
                  <strong>YOUR OWN RISK</strong>
                </span>
                <span size="2">
                  . You understand and agree that the Services are provided to
                  you on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
                  basis. Without limiting the foregoing, to the maximum extent
                  permitted under applicable law, and&nbsp;
                </span>
                Stoprr <span size="2">are</span>
                <span size="2">&nbsp;</span>founders, officers, directors,
                employees, agents, representatives, and partners) DISCLAIM ALL
                WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, and OR
                NON-INFRINGEMENT.
              </p>
              <p>
                Stoprr{" "}
                <span size="2">
                  make no warranty and disclaim all responsibility and liability
                  for: (i) the completeness, accuracy, availability, timeliness,
                  security or reliability of the Services or any Content; (ii)
                  any harm to your computer system, loss of data, or other harm
                  that results from your access to or use of the Services or any
                  Content; (iii) the deletion of, or the failure to store or to
                  transmit, any Content and other communications maintained by
                  the Services; and (iv) whether the Services will meet your
                  requirements or be available on an uninterrupted, secure, or
                  error-free basis. No advice or information, whether oral or
                  written, obtained from&nbsp;
                </span>
                Stoprr or through the Services, will create any warranty not
                expressly made herein.
              </p>
              <p>
                <span size="2">
                  The Services may contain links to third-party websites or
                  resources. You acknowledge and agree that the&nbsp;
                </span>
                Stoprr{" "}
                <span size="2">
                  is not responsible or liable for: (i) the availability or
                  accuracy of such websites or resources; or (ii) the content,
                  products, or services on or available from such websites or
                  resources. Links to such websites or resources do not imply
                  any endorsement by&nbsp;
                </span>
                Stoprr of such websites or resources or the content, products,
                or services available from such websites or resources. You
                acknowledge sole responsibility for and assume all risk arising
                from your use of any such websites or resources.
              </p>
              <p>
                <strong>No Provision of professional or expert advice.</strong>
              </p>
              <p>
                <span size="2">
                  Your use of the platform is solely at your own risk. Nothing
                  on the platform in the means of communication either directed
                  personally or to the public at large, or any specific public,
                  does not provide professional, legal, or financial advice to
                  Users or any other individuals, and assumes no fiduciary
                  duties of Users
                </span>
                .
              </p>
              <p>
                <span size="2">16.</span>
                <span size="2">
                  <strong>CHANGE</strong>
                </span>
                <em>
                  <strong>S</strong>
                </em>
              </p>
              <p>
                <span size="2">If&nbsp;</span>Stoprr{" "}
                <span size="2">
                  decides to change these general terms and conditions, we will
                  post the changed terms and conditions on the Website. You are
                  advised to regularly check whether they have changed. Existing
                  contracts will not be affected by such changes.&nbsp;
                </span>
              </p>
              <p>
                <span size="2">17.</span>
                <strong>GOVERNING LAW AND JURISDICTION</strong>
              </p>
              <p>
                <span size="2">
                  This general terms and conditions in relation to the use
                  of&nbsp;
                </span>
                <span size="2">Stoprr&nbsp;</span>
                <span size="2">
                  is hereby governed by, and constructed and enforced in
                  accordance with the laws of England &amp; Wales
                </span>{" "}
                and are subject to the exclusive jurisdiction of the Courts of
                England &amp; Wales.
              </p>
              <p>
                <br />
                &nbsp;
              </p>
            </WhiteBox>
            <P className="f-right">Updated Febuary 17, 2021</P>
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 120px 15px 70px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 970px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const WhiteBox = styled.div`
  background: #fff;
  max-width: 970px;
  padding: 30px;
  border-radius: 20px;
  margin: 0 auto;
  box-sizing: border-box;
`;
const P = styled.p`
  &.f-right {
    float: right;
  }
`;
