// Dependencies
import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// Presentational components
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import { Input } from "../components/atoms/input/input";
import { Button } from "../components/atoms/button/button";
import { ShareIcon } from "../components/common/shareicon";
//Common Components
import { Error } from "../components/common/message";

//Assets

import img from "../assets/images/sign-up.gif";
import styled from "styled-components";

//Actions
import resetPassword from "../store/resetPassword/actions";

const ResetPassword = () => {
  //Action
  const dispatch = useDispatch();

  //Get params from route
  let { id } = useParams();

  let tempParam = useLocation().search?.split("?email=");

  // State for loader
  const [showLoader, setShowLoader] = useState(false);

  //State for UserInfo
  const [userInfo, setUserInfo] = useState({
    email: tempParam?.[1],
    password: "",
    password_confirmation: "",
    token: id,
  });
  //State For Error Handle
  const [error, setError] = useState({
    email: "",
    password: "",
    token: "",
    password_confirmation: "",
    status: "",
    responseData: "",
  });
  //Set Form Value In State

  const handleClick = (field, value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [field]: "",
      responseData: {
        ...prevState?.responseData,
        data: {
          ...prevState?.responseData?.data,
          [field]: "",
        },
      },
    }));
  };

  const signup = () => {
    setShowLoader(true);
    let valid = true;
    var validEmail = new RegExp(
      /^([a-zA-Z0-9\+\-]+)(\.[a-zA-Z0-9\+\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/
    );
    if (!userInfo.email) {
      setError((prevState) => ({ ...prevState, email: "Email is required" }));
      valid = false;
    } else if (!validEmail.test(userInfo.email)) {
      setError((prevState) => ({
        ...prevState,
        email: "The email must be a valid email address.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, email: "" }));
    }
    if (!userInfo.password) {
      setError((prevState) => ({
        ...prevState,
        password: "Password is required",
      }));
      valid = false;
    } else if (userInfo.password.length < 8) {
      setError((prevState) => ({
        ...prevState,
        password: " The password must be at least 8 characters.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, password: "" }));
    }
    if (!userInfo.password_confirmation) {
      setError((prevState) => ({
        ...prevState,
        password_confirmation: "Confirm password is required",
      }));
      valid = false;
    } else if (userInfo.password_confirmation.length < 8) {
      setError((prevState) => ({
        ...prevState,
        password_confirmation: " The password must be at least 8 characters.",
      }));
      valid = false;
    } else {
      let errorMessage = "";
      if (userInfo.password_confirmation !== userInfo.password) {
        errorMessage = "Password mismatch";
        valid = false;
      }
      setError((prevState) => ({
        ...prevState,
        password_confirmation: errorMessage,
      }));
    }
    if (!userInfo.token) {
      setError((prevState) => ({ ...prevState, token: "Required token" }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, token: "" }));
    }

    if (valid) {
      let responseData = dispatch(resetPassword.resetPassword(userInfo));
      if (responseData) {
        responseData.then((response) => {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            setUserInfo((prevState) => ({
              ...prevState,
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              password_confirmation: "",
            }));
            setError((prevState) => ({
              ...prevState,
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              password_confirmation: "",
              status: "",
              responseData: "",
            }));
            setShowLoader(false);
            setTimeout(function () {
              window.location = "/login";
            }, 2000);
          } else {
            setShowLoader(false);
            // toast.error(response?.data?.message);
            setError((prevState) => ({
              ...prevState,
              responseData: response?.data,
              status: response?.data?.status,
            }));
          }
        });
      }
    } else {
      setShowLoader(false);
    }
  };
  return (
    <>
      <Header />
      <ShareIcon />
      <ContentWrapper>
        <LoginContainer>
          <div>
            <AuthWrapper>
              <LeftHeading largeTitle="Reset Password" />
              <Input
                type="text"
                label="Email"
                disabled
                value={userInfo.email}
                placeholder="Enter your Email"
                onChange={(e) => handleClick("email", e.target.value)}
              />
              <Error
                error={error.email || error?.responseData?.data?.email}
              ></Error>
              <Input
                type="password"
                label="Password"
                value={userInfo.password}
                placeholder="Enter your Password"
                onChange={(e) => handleClick("password", e.target.value)}
              />
              <Error
                error={error.password || error?.responseData?.data?.password}
              ></Error>
              <Input
                type="password"
                label="Confirm Password*"
                value={userInfo.password_confirmation}
                placeholder="Enter your Password Again"
                onChange={(e) =>
                  handleClick("password_confirmation", e.target.value)
                }
              />
              <Error
                error={
                  error.password_confirmation ||
                  error?.responseData?.data?.password_confirmation
                }
              ></Error>
              <Flex>
                <div onClick={() => signup()}>
                  <Button loader={showLoader} name="Reset Password" />
                </div>
              </Flex>
            </AuthWrapper>
            <Account>
              Already have an account?{" "}
              <Link to="/login">
                <strong>Sign In</strong>
              </Link>
            </Account>
          </div>
          <Image src={img} alt="sign in" />
        </LoginContainer>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default ResetPassword;

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 100px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 50px 0;
  }
`;
const LoginContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  max-width: 1320px;
  padding: 50px 15px;
  box-sizing: border-box;
  align-items: center;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    grid-gap: 30px;
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;
const Image = styled.img`
  max-width: 100%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const AuthWrapper = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
`;
/* const ForgotPassword = styled.div`
    cursor: pointer;
` */
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
`;
const Account = styled.div`
  margin-top: 20px;

  strong {
    color: rgba(254, 49, 34, 1);
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;
