import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import FaqImg from "../assets/images/faq.gif";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import _ from "lodash";
import { MdArrowForwardIos } from "react-icons/md";
import { ShareIcon } from "../components/common/shareicon";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
const data = {
  tab1: {
    title: "What is stoprr?",
    description:
      "stoprr is the only tool that effectively helps prevent recruiters from contacting their clients’ staff on LinkedIn and other recruitment websites. stoprr replaces inefficient ‘do not contact’ lists.",
  },
  tab2: {
    title: "How does stoprr work?",
    description:
      "You enter your client (company) names as keywords (manually or via CSV file) into the stoprr platform and all users will be alerted when these company names appear on screen. Your recruiters are made aware that the candidate they’re viewing works for one of your customers.",
  },
  tab3: {
    title: "Can I sync stoprr with Bullhorn?",
    description:
      "Yes, you can! Please head to our <a href='/tutorials'>Tutorials page</a> for instructions or to our <a href='/contact'>Contact Us</a> page to request further information.",
  },
  tab4: {
    title: "What payment processing system does stoprr use?",
    description: "Stripe",
  },
  tab5: {
    title: "How many users can I add to stoprr?",
    description: "As many as you like, infinite!",
  },
  tab6: {
    title: "Can I use stoprr on any web browser?",
    description: "No, currently only on Google Chrome.",
  },
  tab7: {
    title: "How long does the free trial last?",
    description:
      "The free trial lasts for two months, after which point your card will be charged for further use unless you cancel your subscription or trial.",
  },
  tab8: {
    title: "Can I cancel at any time?",
    description:
      "Yes, you can! You can cancel during the free trial or during a paid month.",
  },
  tab9: {
    title: "I am struggling to download stoprr, please help?",
    description:
      "Please head to our <a href='/tutorials'>Tutorials page</a>, speak to our chat bot or <a href='/contact'>Contact Us</a>.",
  },
  tab10: {
    title: "stoprr isn’t working and I can’t fix it.",
    description:
      "Please head to our <a href='/tutorials'>Tutorials page</a>, speak to our chat bot or <a href='/contact'>Contact Us</a>.",
  },
  tab11: {
    title: "Is stoprr secure?",
    description:
      "Yes absolutely. Please head to our <a href='/privacy-policy'>‘Privacy Policy’</a> for more information on how we secure and respect the privacy of your data.",
  },
  tab12: {
    title:
      "What will happen to my data when I cancel my trial or monthly contract?",
    description:
      "All your data will be automatically deleted upon cancellation. stoprr does not retain any customer information from within the platform.",
  },
  tab13: {
    title: "How can I get in touch?",
    description: "<a href='/contact'>Get in touch here</a>",
  },
};

export default class FAQ extends Component {
  render() {
    return (
      <>
        <Header />
        <ShareIcon />
        <BannerWrapper>
          <Container>
            <LeftHeading largeTitle="Frequently Asked Questions" />
            <Image className="xs-d-none" src={FaqImg} alt="about us" />
          </Container>
        </BannerWrapper>
        <ContentWrapper>
          {/* <TabsWrapper className="hidden-md">
            <Tabs>
              <TabList>
                {_.map(data, (items, index) => (
                  <Tab key={index}>
                    {items.title} <MdArrowForwardIos size={20} />
                  </Tab>
                ))}
              </TabList>
              {_.map(data, (items, index) => (
                <TabPanel>
                  <div className="panel-content">
                    <H2>{items.title}</H2>
                    <P
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </TabsWrapper> */}
          <TabsWrapper>
            <Accordion>
              {_.map(data, (items, index) => (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>{items.title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p
                      dangerouslySetInnerHTML={{ __html: items.description }}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsWrapper>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  padding: 80px 0 120px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  &:before {
    content: "";
    height: 40px;
    width: 40px;
    background: #fff;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 99;
  }

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const TabsWrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  &.hidden-md {
    display: none;
    @media only screen and (min-width: 992px) {
      display: block;
    }
  }

  &.visible-md {
    display: none;
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  .accordion {
    &__heading {
      list-style: none;
      cursor: pointer;
      color: #151417;
      margin-bottom: 15px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__panel {
      padding: 0 20px;
      margin-bottom: 40px;

      p {
        a {
          font-weight: 600;
          color: #000;
          text-decoration: none;
        }
      }
    }
    &__button {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #19215833;

      &::after {
        display: inline-block;
        content: "";
        height: 10px;
        width: 10px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
        transition: transform 300ms ease;
      }

      &[aria-expanded="true"] {
        background: rgb(254, 218, 0);
        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
  .react-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @media only screen and (max-width: 991px) {
      display: block;
    }

    &__tab-list {
      margin: 0;
      padding: 0;
    }
    &__tab {
      list-style: none;
      padding: 20px;
      cursor: pointer;
      color: #151417;
      border: 1px solid #19215833;
      border-radius: 10px;
      margin-bottom: 15px;
      background: #ffffff;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--selected {
        background: #feda00;
      }
    }
    &__tab-panel {
      display: none;

      &--selected {
        display: block;
      }
    }
  }
`;
const Image = styled.img`
  max-width: 100%;

  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    &.xs-d-none {
      display: none;
    }
  }
`;
const BannerWrapper = styled.div`
  background: #fff;
  padding: 150px 0 100px 0;
  position: relative;
  z-index: 9;
`;
const H2 = styled.h2`
  margin: 0;
  font-size: 27px;
  line-height: 32px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    height: 4px;
    width: 80px;
    background: #fe3122;
  }
`;
const P = styled.p``;
