//Services
import StoperApi from '../../services/StoperApi'


//Define NameSpace

const NS = '@stopper/verify_email';


//Define Action Type

export const actionTypes = {
    VERIFY_EMAIL_REQUEST: `${NS}/VERIFY_EMAIL_REQUEST`,
    VERIFY_EMAIL_SUCCESS: `${NS}/VERIFY_EMAIL_SUCCESS`,
    VERIFY_EMAIL_FAILURE: `${NS}/VERIFY_EMAIL_FAILURE`,
}

const action = (type, payload) => ({ type, payload });

//Define Actions

const actions = {
    verifyEmail: (id,hash,tempParam) => {
        return async dispatch => {
            dispatch(
                action(
                    actionTypes.VERIFY_EMAIL_REQUEST,
                    {}
                )
            );

            return StoperApi
                .verifyEmail(id,hash,tempParam)
                .then(response => {
                    dispatch(action(
                        actionTypes.VERIFY_EMAIL_SUCCESS,
                        response?.data
                    ));
                    return response;
                })
                .catch(err => {
                    dispatch(action(
                        actionTypes.VERIFY_EMAIL_FAILURE,
                        {
                          error:err?.response?.data
                        }
                    ));
                    return err?.response;
                }
                );


        }
    }
}

// Export actions
export default actions;