import styled from "styled-components";

export const Button = (props) => {
  return (
    <ButtonWrapper style={props.style} {...props}>
      {props.loader ? <span className="loader"></span> : props.name}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  border: 0;
  padding: 15px 45px;
  background: rgba(254, 49, 34, 1);
  color: #fff;
  border-radius: 24px;
  font-size: 14px;
  cursor: pointer;
  min-width: 135px;

  @media only screen and (max-width: 767px) {
    padding: 15px 30px;
  }
`;
