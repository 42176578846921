import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LogIn from "./pages/login";
import ContactUs from "./pages/contact";
import SignUp from "./pages/signup";
import Home from "./pages/home";
import About from "./pages/about";
import Subscription from "./pages/subscription";
import FAQ from "./pages/faq";
import Tutorials from "./pages/tutorials";
import TermsConditions from "./pages/terms-conditions";
import CookiesPolicy from "./pages/cookies-policy";
import PrivacyPolicy from "./pages/privacy-policy";
import EmailVerify from "./pages/emailVerify";
import ResetPassword from "./pages/resetPassword";
import Toaster from "./components/common/toaster";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import React, { useRef } from "react";
function App() {
  const tawkMessengerRef = useRef();

  return (
    <>
      <TawkMessengerReact
        propertyId="63592fa3daff0e1306d417c2"
        widgetId="1gga646ue"
        ref={tawkMessengerRef}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/email-verify/:id/:hash" element={<EmailVerify />} />
          <Route path="/email-verify/:id/:hash" element={<EmailVerify />} />
          <Route path="/password-reset/:id/" element={<ResetPassword />} />
        </Routes>
        <Toaster></Toaster>
      </BrowserRouter>
    </>
  );
}

export default App;
