// Dependencies
import axios from 'axios';

// Default API will be your root
const API_ROOT = process.env.REACT_APP_DOMAIN || 'http://localhost:3000/';
const TIMEOUT = 20000;
const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

// Define class
class ApiService {
  constructor({
    baseURL = API_ROOT,
    timeout = TIMEOUT,
    headers = HEADERS,
    auth
  }) {
    const client = axios.create({
      baseURL,
      timeout,
      headers,
      auth,
    });

    client.interceptors.response.use(this.handleSuccess, this.handleError);
    this.client = client;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    return Promise.reject(error);
  }

  get(path,payload) {
    return this.client.get(path,payload).then(response => response);
  }

  post(path, payload) {
    return this.client.post(path, payload).then(response => response);
  }

  put(path, payload) {
    return this.client.put(path, payload).then(response => response);
  }

  patch(path, payload) {
    return this.client.patch(path, payload).then(response => response);
  }

  delete(path) {
    return this.client.delete(path).then(response => response);
  }
};

// Export class
export default ApiService;
