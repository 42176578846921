//Action
import { actionTypes } from './actions'

//Define Innitial State

const getInitialState = () => ({
    isAuthenticated: sessionStorage.getItem('id_token') ? true : false,
    isFetching: false,
    isSuccess: false,
    error: ''
})


const auth = (state = getInitialState(), { type, payload }) => {
    switch (type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true,
                isSuccess: false,
                error: ''
            }
          
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                isSuccess: true,
                isFetching: false,
                error: ''
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                ...payload,
                isAuthenticated: false,
                isSuccess: false,
                isFetching: false
            };


        default:
            return state;
          
    }
}

//Exprt reducer
export default auth;