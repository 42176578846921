import styled from "styled-components";

export const LeftHeading = (props) => {
  return (
    <HeadingWrapper className={props.className}>
      <SmallText>{props.smallTitle}</SmallText>
      <H2>{props.largeTitle}</H2>
    </HeadingWrapper>
  );
};

const HeadingWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 10px;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    height: 6px;
    width: 80px;
    background: #fe3122;
  }

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 160px;
    background: #151417;
  }
`;
const H2 = styled.h2`
  font-size: 34px;
  line-height: 41px;
  font-weight: 700;
  margin: 0 0 15px 0;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`;
const SmallText = styled.p`
  margin: 0;
  text-transform: uppercase;
`;
