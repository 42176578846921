import styled from "styled-components";
import logo from "../../assets/images/logo.svg";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

export const Header = () => {
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <StyledHeader>
      <ContainerFluid>
        <FlexWrapper>
          <Link to="/">
            <Image src={logo} alt="logo" />
          </Link>
          <Nav className={!isActive ? "open-menu" : null}>
            <AiOutlineClose
              onClick={handleToggle}
              className="mbl-close"
              size={24}
            />
            <ul>
              <li>
                <NavLink exactly="true" to="/" activeclassname="active">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink exactly="true" to="/about" activeclassname="active">
                  Why stoprr
                </NavLink>
              </li>
              <li>
                <NavLink
                  exactly="true"
                  to="/tutorials"
                  activeclassname="active"
                >
                  Tutorials
                </NavLink>
              </li>
              <li>
                <NavLink
                  exactly="true"
                  to="/subscription"
                  activeclassname="active"
                >
                  Subscriptions
                </NavLink>
              </li>
              <li>
                <NavLink exactly="true" to="/contact" activeclassname="active">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </Nav>
          <Flex>
            <Link to="/login">
              <LoginButton>Login</LoginButton>
            </Link>
            <FaBars onClick={handleToggle} className="mbl-menu" size={24} />
          </Flex>
        </FlexWrapper>
      </ContainerFluid>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 22px 0;
  background: #fff;
  box-shadow: 0 0px 11px rgb(0 0 0 / 10%);
  z-index: 999;

  @media only screen and (max-width: 767px) {
    padding: 10px 0;
  }
`;
const ContainerFluid = styled.div`
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  max-width: 1600px;
  margin: 0 auto;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mbl-menu {
    color: #fe3122;
    margin-left: 15px;
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
`;
const Image = styled.img`
  display: block;
`;
const LoginButton = styled.button`
  background: #fe3122;
  border-radius: 24px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 44px;
  text-decoration: none;
  border: 0;
  cursor: pointer;

  a {
    color: #fff;
    text-decoration: none;
    display: block;
  }
  @media only screen and (max-width: 767px) {
    padding: 10px 20px;
  }
`;
const Nav = styled.nav`
  flex: 1;

  @media only screen and (max-width: 767px) {
    position: fixed;
    right: 0;
    top: 0;
    width: 250px;
    height: 100vh;
    background: #fff;
    z-index: 0;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: 0.15s all ease-in-out;
    opacity: 0;
    pointer-events: none;

    &.open-menu {
      opacity: 1;
      pointer-events: all;

      &:before {
        left: 0;
      }
    }

    &:before {
      content: "";
      position: fixed;
      left: 100%;
      top: 0;
      background: rgba(21, 20, 23, 0.9);
      height: 100vh;
      width: calc(100vw - 250px);
    }
  }
  .mbl-close {
    position: absolute;
    top: 20px;
    right: 15px;
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: center;

    @media only screen and (max-width: 767px) {
      text-align: left;
    }

    li {
      list-style: none;
      margin: 0 30px;
      display: inline-block;

      @media only screen and (max-width: 991px) {
        margin: 0 15px;
      }
      @media only screen and (max-width: 767px) {
        display: block;
        margin: 25px 0;
      }
      a {
        color: #192158;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        text-decoration: none;
        position: relative;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        &:before {
          content: "";
          position: absolute;
          top: calc(100% + 10px);
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          width: 100%;
          background: #2d97ff;
          display: none;
        }

        &:hover,
        &.active {
          color: #2d97ff;

          &:before {
            display: block;
          }
        }
      }
    }
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;
