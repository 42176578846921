//Services
import StoperApi from "../../services/StoperApi";

// Define namespace
const NS = '@bondboard/user';

// Define action names
export const actionTypes = {
    SHARE_SEND_REQUEST: `${NS}/SHARE_SEND_REQUEST`,
    SHARE_SEND_SUCCESS: `${NS}/SHARE_SEND_SUCCESS`,
    SHARE_SEND_FAILURE: `${NS}/SHARE_SEND_FAILURE`,
}


const action = (type, payload) => ({ type, payload });

const actions = {
    shareUrl: (payload = {}) => {
        
        return async dispatch => {
            dispatch(
                action(
                    actionTypes.SHARE_SEND_REQUEST,
                    {}
                )
            );

            return StoperApi.shareUrl(payload).then(response => {
                dispatch(
                    action(
                        actionTypes.SHARE_SEND_SUCCESS,
                        response?.data
                    )
                );
                return response;
            }).catch(err => {
                dispatch(
                    action(
                        actionTypes.SHARE_SEND_FAILURE,
                        err?.response?.data
                    )
                );
                return err?.response
            })
        }

    }
}


export default actions;