// Dependencies
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// Presentational components
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";

//Assets

import styled from "styled-components";

//Actions
import verifyEmail from "../store/verifyEmail/actions";
import { ShareIcon } from "../components/common/shareicon";

export default function EmailVerify() {
  //Get params from route
  let { id, hash } = useParams();
  //Navigate Route
  let navigate = useNavigate();
  //Action
  const dispatch = useDispatch();
  let tempParam = useLocation().search;

  //State For Error Handle
  const [error, setError] = useState({
    status: "",
    responseData: "",
  });

  useEffect(() => {
    if (id && hash) {
      let responseData = dispatch(verifyEmail.verifyEmail(id, hash, tempParam));
      if (responseData) {
        responseData.then((response) => {
          if (response?.status === 200) {
            //toast.success(response?.data?.message || 'Success')
            setError((prevState) => ({
              ...prevState,
              responseData: response?.data,
              status: response?.data?.status,
            }));
            setTimeout(() => {
              navigate("/login");
            }, 1000);
          } else {
            toast.error(response?.data?.message || "Error");
          }
        });
      }
    }
  }, [id, hash, tempParam, dispatch, navigate]);
  return (
    <div>
      <Header />
      <ShareIcon />
      <ContentWrapper>
        <H2>{error?.responseData?.message}</H2>
      </ContentWrapper>
      <Footer />
    </div>
  );
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 100px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 50px 0;
  }
`;
const H2 = styled.h2`
  color: #00a651;
  font-size: 20px;
  line-height: 30px;
`;
