import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import PeopleImg from "../assets/images/people.svg";
import BG from "../assets/images/bg-subscription.png";
import Hooks from "../assets/images/hooks.png";
import { MdCheck } from "react-icons/md";
import { Link } from "react-router-dom";
import { ShareIcon } from "../components/common/shareicon";
export default class Subscription extends Component {
  render() {
    return (
      <>
        <Header />
        <ShareIcon />
        <ContentWrapper>
          <Container>
            <div>
              <LeftHeading largeTitle="Subscriptions" />
              <P>
                Start your free <br />
                trial now
              </P>
              <Image src={PeopleImg} />
            </div>
            <SubscriptionBox>
              <H2>Subscription Plan</H2>
              <BottomBox>
                <List>
                  <ListItems>
                    <MdCheck size={20} /> 2 Months Free Trial Period
                  </ListItems>
                  <ListItems>
                    <MdCheck size={20} /> Automated Payment Method
                  </ListItems>
                </List>
                <Price>
                  $<strong>5</strong>/per month
                </Price>
                <Link to="/signup">
                  <Button>Join Now</Button>
                </Link>
              </BottomBox>
            </SubscriptionBox>
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}

const ContentWrapper = styled.div`
  background: #f7f7fb url(${BG});
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 220px 15px 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 90px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 330px;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 1399px) {
    grid-gap: 100px;
  }
  @media only screen and (max-width: 1199px) {
    grid-gap: 100px;
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const Image = styled.img`
  max-width: 100%;
  margin-top: 100px;

  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
const P = styled.p`
  font-size: 34px;
  line-height: 41px;
  margin: 0;

  @media only screen and (max-width: 991px) {
    br {
      display: none;
    }
  }
`;
const SubscriptionBox = styled.div`
  background: #2d97ff;
  border-radius: 50px;
  max-width: 470px;
  position: relative;
  float: right;

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 991px) {
    float: none;
    margin-top: 50px;
  }

  &:before {
    content: "";
    background: #151417;
    height: 1px;
    width: 550px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (max-width: 1399px) {
      display: none;
    }
  }
`;
const H2 = styled.h2`
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background: #2a8ceb;
  margin: 0;
  padding: 70px 50px 30px;
  border-radius: 50px 50px 0 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background: url(${Hooks});
    width: 231px;
    height: 62px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 20px 30px;
    font-size: 30px;
  }
`;
const BottomBox = styled.div`
  padding: 30px 50px;

  @media only screen and (max-width: 480px) {
    padding: 30px 20px;
  }
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const ListItems = styled.li`
  font-size: 21px;
  line-height: 26px;
  color: #fff;
  margin-bottom: 10px;

  svg {
    vertical-align: middle;
  }

  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
const Price = styled.div`
  font-size: 42px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin: 70px 0;

  strong {
    font-size: 110px;

    @media only screen and (max-width: 480px) {
      font-size: 70px;
    }
  }

  @media only screen and (max-width: 480px) {
    font-size: 22px;
    margin: 30px;
  }
`;
const Button = styled.button`
  border-radius: 50px;
  font-size: 21px;
  display: block;
  color: #192158;
  padding: 22px;
  width: 100%;
  background: #fedb00;
  border: 0;
  margin-bottom: 20px;
  cursor: pointer;
`;
