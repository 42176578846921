//Action Types
import { actionTypes } from "../user/actions";

// Define initial state
const getInitialState = () => ({
    details: {},
    isFetching: false,
    isSuccess: false,
    error: ''
  });

  const user = (state = getInitialState(), { type, payload }) => {
    switch (type) {
        case actionTypes.USER_CREATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                isSuccess: false,
                error: ''
            }
           
        case actionTypes.USER_CREATE_SUCCESS:
            return {
                ...state,
                details:payload,
                isSuccess: true,
                isFetching: false,
                error: ''
            };
        case actionTypes.USER_CREATE_FAILURE:
            return {
                ...state,
                error:payload,
                isFetching: false,
                isSuccess: false,
            };


        default:
            return state;
           
    }
}


//Exprt reducer
export default user;