
import ApiService from './Api';
/* import dotenv from "dotenv";
dotenv.config(); */

// Base URL from where all API calls are sent
const BASE_URL = process.env.REACT_APP_DOMAIN;//'https://stoprr.hestawork.com/api/v1/';

// Base headers for all API calls
 const HEADERS = {
  'X-Requested-With': 'XMLHttpRequest',
   'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': '*',
    'maxRedirects': '0'
}

// Create instance of the Axios API service
const service = new ApiService({ baseURL: BASE_URL, headers: HEADERS});

// Custom interceptor to add authorizarion header

service.client.interceptors.request.use(request => {
  const token = sessionStorage.getItem('id_token');
  if (!!token) request.headers.Authorization = `Bearer ${token}`;

  return request;
});

//The base Stoper Api Object

const StoperApi = {};

//Authentication
StoperApi.userLogin = credentials => service.post('login', credentials);
//StoperApi.userLogin = credentials => service.get('sanctum/csrf-cookie');


// Create User
StoperApi.userCreate = payload => service.post('register', payload)

//Send Message
StoperApi.sendMessage = payload => service.post('contact', payload);

//verifyEmail 
StoperApi.verifyEmail = (id,hash,tempParam) => service.get(`verify-email/${id}/${hash}${tempParam}`);

//forgetPassword
StoperApi.forgetPassword = (payload) => service.post('forgot-password',payload);

//resetPassword
StoperApi.resetPassword = (payload) => service.post('reset-password',payload);

// Share URL
StoperApi.shareUrl = payload => service.post('share-url', payload)




export default StoperApi;