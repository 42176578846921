import styled from "styled-components";
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from "react-router-dom";

export const Footer = () => {

    const getYear = () => {
        return new Date().getFullYear();
    }
    return (
        <StyledFooter>
            <Container>
                <FlexWrapper>
                    {/* <SocialIconList>
                        <li><a href=""><FaFacebookF color="#fff" /></a></li>
                        <li><a href=""><FaTwitter color="#fff" /></a></li>
                        <li><a href=""><FaInstagram color="#fff" /></a></li>
                    </SocialIconList> */}
                    <QuickLinks>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/cookies-policy">Cookies Policy</Link></li>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                        <li><Link to="/faq">FAQ’s</Link></li>
                    </QuickLinks>
                    <Copyright>Copyright © {getYear()}</Copyright>
                </FlexWrapper>
            </Container>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 30px 0;
    // z-index: -1;
`
const Container = styled.div`
    max-width: 1320px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 0 auto;
`
const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
        display: block;
    }
`
const SocialIconList = styled.ul`
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    list-style: none;

    @media only screen and (max-width: 991px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    li {
        margin-right: 25px;
        display: flex;

        &:last-child{
            margin-right: 0;
        }

        a {
            color: #fff;

            svg {
                vertical-align: middle;
            }
        }
    }
`
const QuickLinks = styled.ul`
    margin: 0;
    padding: 0;
    display: inline-flex;
    list-style: none;
    flex: 1;
    margin-left: 40px;

    @media only screen and (max-width: 991px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;  
    }

    @media only screen and (max-width: 767px) {
        display: block;
        text-align: center;
        margin: 10px 0;
    }

    li {
        margin-right: 15px;

         @media only screen and (max-width: 767px) {
            margin: 10px 0;
         }
        a {
            color: #fff;
            font-size: 14px;
            text-decoration: none;
        }
    }
`
const Copyright = styled.div`
    color: #fff;
    font-size: 14px;
    text-decoration: none;

    @media only screen and (max-width: 991px) {
        text-align: center;
    }
`