// Dependencies
import React, { useState } from "react";
import { FiMapPin, FiPhone } from "react-icons/fi";
import { BiEnvelope } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Presentational components
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import { Input } from "../components/atoms/input/input";
import { TextArea } from "../components/atoms/input/textarea";
import { Button } from "../components/atoms/button/button";

//Assets
import ContactImg from "../assets/images/contact-us.gif";
import styled from "styled-components";

//Common Components
import { Error } from "../components/common/message";

//Actions
import contact from "../store/contact/action";
import { ShareIcon } from "../components/common/shareicon";
const ContactUs = () => {
  // React.useEffect(() => {
  //      window.addEventListener('keydown', (event) => {
  //      if(event.key=='Enter'){
  //         sendMessage();
  //       }
  //     });
  // });

  //Action
  const dispatch = useDispatch();

  //Use Selector
  //const contactState = useSelector((state) => state?.contact);

  // State for loader
  const [showLoader, setShowLoader] = useState(false);

  //State for contactInfo
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  //State For Error Handle
  const [error, setError] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    status: "",
    responseData: "",
  });

  //Action call on contact
  const sendMessage = () => {
    setShowLoader(true);
    let valid = true;
    var validName = new RegExp(/^[a-zA-Z0-9\s]+$/);
    var validEmail = new RegExp(
      /^([a-zA-Z0-9\+\-]+)(\.[a-zA-Z0-9\+\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/
    );
    if (!contactInfo.name) {
      setError((prevState) => ({
        ...prevState,
        name: "Full name is required",
      }));
      valid = false;
    } else if (!validName.test(contactInfo.name)) {
      setError((prevState) => ({
        ...prevState,
        name: "Full name only contain alpha-numeric characters",
      }));
      valid = false;
    } else if (contactInfo.name.length < 2) {
      setError((prevState) => ({
        ...prevState,
        name: "Full name should be min 2 characters",
      }));
      valid = false;
    } else if (contactInfo.name.length > 100) {
      setError((prevState) => ({
        ...prevState,
        name: "Full name should be max 100 characters",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, name: "" }));
    }
    if (!contactInfo.email) {
      setError((prevState) => ({ ...prevState, email: "Email is required" }));
      valid = false;
    } else if (!validEmail.test(contactInfo.email)) {
      setError((prevState) => ({
        ...prevState,
        email: "The email must be a valid email address.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, email: "" }));
    }
    if (!contactInfo.subject) {
      setError((prevState) => ({
        ...prevState,
        subject: "Subject is required",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, subject: "" }));
    }
    if (!contactInfo.message) {
      setError((prevState) => ({
        ...prevState,
        message: "Message is required",
      }));
      valid = false;
    } else if (contactInfo.message.length > 300) {
      setError((prevState) => ({
        ...prevState,
        message: "The message must not be greater than 300 characters.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, message: "" }));
    }
    if (valid) {
      let responseData = dispatch(contact.sendMessage(contactInfo));
      if (responseData) {
        responseData.then((response) => {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            setContactInfo((prevState) => ({
              ...prevState,
              name: "",
              email: "",
              subject: "",
              message: "",
            }));
            setError((prevState) => ({
              ...prevState,
              name: "",
              email: "",
              subject: "",
              message: "",
              status: "",
              responseData: "",
            }));
            setShowLoader(false);
          } else {
            setShowLoader(false);
            //toast.error(response?.data?.message);
            setError((prevState) => ({
              ...prevState,
              responseData: response?.data,
              status: response?.data?.status,
            }));
          }
        });
      }
    } else {
      setShowLoader(false);
    }
  };
  //Set setContactInfo state on onchange
  const handleClick = (field, value) => {
    setContactInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      [field]: "",
      responseData: {
        ...prevState?.responseData,
        data: {
          ...prevState?.responseData?.data,
          [field]: "",
        },
      },
    }));
  };
  // let buttonId=document.getElementById("send-message");
  //  console.log("buttonId");

  //    window.addEventListener('keydown', function (e) {
  //         console.log("buttonId",e.key);
  //         if (e.key === 'Enter') {
  //             sendMessage();
  //         }
  //     });
  return (
    <>
      <Header />
      <ShareIcon />
      <ContentWrapper>
        <LoginContainer>
          <div>
            <LeftHeading smallTitle="CONTACT US" largeTitle="Just Say Hello!" />
            <Input
              type="text"
              label="Full Name*"
              placeholder="Enter Your Full Name"
              value={contactInfo.name}
              style={{ background: "#fff" }}
              onChange={(e) => handleClick("name", e.target.value)}
            />
            {error.name ? (
              <Error
                className="error"
                error={error.name || error?.responseData?.data?.name}
              />
            ) : null}
            <Input
              type="text"
              label="Email*"
              value={contactInfo.email}
              placeholder="Enter your Email"
              style={{ background: "#fff" }}
              onChange={(e) => handleClick("email", e.target.value)}
            />
            {error.email ? (
              <Error
                className="error"
                error={error.email || error?.responseData?.data?.email}
              />
            ) : null}

            <Input
              type="text"
              label="Subject*"
              value={contactInfo.subject}
              placeholder="Enter your Subject"
              style={{ background: "#fff" }}
              onChange={(e) => handleClick("subject", e.target.value)}
            />
            {error.subject ? (
              <Error
                className="error"
                error={error.subject || error?.responseData?.data?.subject}
              />
            ) : null}

            <TextArea
              label="Message*"
              value={contactInfo.message}
              placeholder="Enter your Message"
              style={{ background: "#fff" }}
              onChange={(e) => handleClick("message", e.target.value)}
            />
            {error.message ? (
              <Error
                className="error"
                error={error.message || error?.responseData?.data?.message}
              />
            ) : null}
            <Flex>
              {/*  {error?.status === 200 ? <Success success={error?.responseMessage}></Success> :
                                <Error className="error" error={error?.responseMessage}/>
                            } */}
              <div id="send-message" onClick={() => sendMessage()}>
                <Button loader={showLoader} name="Send" />
              </div>
            </Flex>
          </div>
          <div>
            <Image src={ContactImg} alt="sign in" />
            <ContactList>
              <ContactListItem>
                <Icon style={{ background: "#2D97FF" }}>
                  <FiMapPin />
                </Icon>
                171 Madison Ave, New York, NY 10016, United States
              </ContactListItem>
              <ContactListItem>
                <Icon style={{ background: "#2D97FF" }}>
                  <FiMapPin />
                </Icon>
                Kenwood House, 1 Upper Grosvenor Rd, Tunbridge Wells, United
                Kingdom, TN1 2EL
              </ContactListItem>
              <ContactListItem>
                <Icon style={{ background: "#FEDA00" }}>
                  <BiEnvelope size={18} />
                </Icon>
                contact@stoprr.com
              </ContactListItem>
              <ContactListItem>
                <Icon style={{ background: "#00d900" }}>
                  <BiEnvelope size={18} />
                </Icon>
                support@stoprr.com
              </ContactListItem>
            </ContactList>
          </div>
        </LoginContainer>
      </ContentWrapper>
      <Footer />
    </>
  );
};

export default ContactUs;

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 150px 0 80px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const LoginContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  max-width: 1320px;
  padding: 0 15px;
  box-sizing: border-box;
  align-items: center;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;
const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const ContactList = styled.ul`
  margin-left: 20px;

  @media only screen and (max-width: 991px) {
    margin: 50px 0 0;
    padding: 0;
  }
`;
const ContactListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const Icon = styled.div`
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
