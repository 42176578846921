import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { CenteredHeading } from "../components/atoms/heading/centered-heading";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import TutorialsImg from "../assets/images/How-Stoprr-Works.gif";
import One from "../assets/images/add1.png";
import Two from "../assets/images/add2.jpg";
import Three from "../assets/images/add3.jpg";
import Four from "../assets/images/add4.jpg";
import Five from "../assets/images/add5.jpg";
import bullhum1 from "../assets/images/bullhum1.jpg";
import bullhum2 from "../assets/images/bullhum2.jpg";
import bullhum3 from "../assets/images/bullhum3.jpg";
import bullhum4 from "../assets/images/bullhum4.jpg";
import bullhum5 from "../assets/images/bullhum5.jpg";
import client1 from "../assets/images/client1.jpg";
import client2 from "../assets/images/client2.jpg";
import client3 from "../assets/images/client3.jpg";
import client4 from "../assets/images/client4.jpg";
import client5 from "../assets/images/client5.jpg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ShareIcon } from "../components/common/shareicon";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import video from "../assets/video/Tutorials-video.mp4";

export default class Tutorials extends Component {
  render() {
    return (
      <>
        <Header />
        <ShareIcon />
        <BannerWrapper>
          <Container>
            <Grid>
              <VideoWrapper>
                <LeftHeading largeTitle="Tutorials" />
                <video
                  id="vid"
                  controls
                  src={video}
                  style={{ width: "100%", height: "100%" }}
                />
              </VideoWrapper>
              <Image className="xs-d-none img-tutorials" src={TutorialsImg} alt="Tutorials" />
            </Grid>
          </Container>
        </BannerWrapper>
        <ContentWrapper>
          <Container>
            <CenteredHeading
              largeTitle="All Tutorials"
              smallTitle="How to download, integrate and manage stoprr"
            />
            <TutorialTabs>
              <Tabs>
                <TabList>
                  <Tab>Add stoprr Extension</Tab>
                  <Tab>Fetching data from CSV and Bullhorn CRM</Tab>
                  <Tab>Clients' Staff Identified</Tab>
                </TabList>

                <TabPanel>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 1</P>
                      <H2>Onboarding the user and adding extension</H2>
                      <P>
                        Signup as a Company User from the website{" "}
                        <strong>Sign up</strong> page.
                      </P>
                      <P>
                        After signing up, the user will need to verify the email
                        account via an email sent to the user's registered email
                        address..
                      </P>
                    </div>
                    <Image src={One} />
                  </Grid>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 2</P>
                      <H2>Updating Company Admin profile.</H2>
                      <P>
                        After signing up, the user needs to fill in all required
                        details for the Company on the Company profile page.
                      </P>
                      <P>
                        User can add client's names in the{" "}
                        <strong>"Add Keywords"</strong> field. These words will
                        be highlighted when using Stoprr. User need to add a
                        “comma” after each client name.
                      </P>
                    </div>
                    <Image src={Two} />
                  </Grid>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 3</P>
                      <H2>Verifying payment account.</H2>
                      <P>
                        It is mandatory for the user to update card details for
                        future payment via a secured Stripe payment gateway,
                        after which the user will be able to add extension users
                      </P>
                    </div>
                    <Image src={Three} />
                  </Grid>
                  <Grid>
                    <div>
                      <P className="mb-0">Step 4</P>
                      <H2>Add extension users.</H2>
                      <P>
                        The Company Admin needs to click on{" "}
                        <strong>"Add User"</strong> via the
                        <strong> "Subscribed Users"</strong> page to onboard the
                        users who will be using the extension. Don't forget to
                        add yourself!
                      </P>
                      <P>
                        <strong>
                          The Company Admin needs to fill in the following
                          details to add a user:
                        </strong>
                      </P>
                      <P>
                        - First Name
                        <br /> - Last Name
                        <br />
                        - Email address (on which the user will be receiving the
                        credentials of the account)
                        <br />
                        - Password
                        <br />- Confirm password
                      </P>
                      <P>
                        New extension users will be able to get credentials on
                        their email mentioned by Company Admin.
                      </P>
                      <P>
                        From those credentials users will be able to login into
                        the extension.
                      </P>
                      <P>
                        Extension users will be directed to install the google
                        chrome extension: stoprr (The image will be added after
                        getting the extension uploaded)
                      </P>
                      <P>
                        When the user is added after the free trial is over,
                        then the Company Admin is required to pay the amount for
                        that user until the next subscription cycle starts
                      </P>
                    </div>
                    <Slider>
                      <Carousel showThumbs={false} autoPlay="true">
                        <div>
                          <Image src={Four} />
                        </div>
                        <div>
                          <Image src={Five} />
                        </div>
                      </Carousel>
                    </Slider>
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 1</P>
                      <H2>Importing Keywords through CSV</H2>
                      <P>
                        - Company Admin needs to download the sample CSV/Excel
                        sheet from Company profile page
                        <br />- After this the user needs to add Company names
                        to the CSV/Excel sheet
                        <br />- Upload the CSV/Excel sheet
                        <br />- The Company names will be added
                        <br />- The system will not duplicate Company names that
                        have been added manually and/or in the Excel sheet.
                      </P>
                    </div>
                    <Slider>
                      <Image src={bullhum5} />
                    </Slider>
                  </Grid>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 2</P>
                      <H2>Required changes in Bullhorn</H2>

                      <P>
                        - The user firstly needs to login into Bullhorn&nbsp;
                        <a
                          href="https://universal.bullhornstaffing.com/universal-login/login"
                          _target="black"
                        >
                          (https://universal.bullhornstaffing.com/universal-login/login)
                        </a>
                        <br />
                        - The user needs to go through the journey: Menu → Admin
                        → Field Mappings
                        <br />
                        - Select the private label if in case there are multiple
                        labels created
                        <br />
                        - Select Company option from the list
                        <br />- Need to search “customText4” field under Company
                      </P>
                    </div>
                    <Image src={bullhum1} />
                  </Grid>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 3</P>
                      <H2>Changes in “customText4” field</H2>
                      <P>
                        - Display option: user need to mention{" "}
                        <strong>"Signed Contract"</strong>
                        <br />- Edit type: user need to choose{" "}
                        <strong>"Drop Down"</strong> option
                        <br />- Required option: user need to select{" "}
                        <strong>"No"</strong> <br />- Hidden option: user need
                        to select <strong>"No"</strong> <br />- Allow Multiple
                        Values: Select <strong>"Single"</strong>
                        <br />
                        - Sort Order: Select the number which is not used by
                        other fields
                        <br />- Value List: Add the options{" "}
                        <strong>"Yes, No"</strong>
                        <br />- Display List: Add the options{" "}
                        <strong>"Yes, No"</strong>
                        <br />- Description: Add the text{" "}
                        <strong>
                          "Do we have a signed contract with this client"
                        </strong>
                      </P>
                      <P>
                        Company Users need to connect with the Bullhorn team to
                        get the Client ID, Client Secret ID, Username and
                        password so that all the keywords which exist on
                        Bullhorn, will be imported via cron into your stoprr
                        platform. Company names will sync daily from Bullhorn.
                      </P>
                      <P>
                        Using the Fetch functionality will allow you to fetch
                        the Client names from Bullhorn instantly.
                      </P>
                    </div>
                    <Slider>
                      <Carousel showThumbs={false} autoPlay="true">
                        <div>
                          <Image src={bullhum2} />
                        </div>
                        <div>
                          <Image src={bullhum4} />
                        </div>
                        <div>
                          <Image src={bullhum3} />
                        </div>
                      </Carousel>
                    </Slider>
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid className="mb-120">
                    <div>
                      <P className="mb-0">Step 1</P>
                      <H2>Clients' Staff Identified</H2>
                      <P>
                        - The extension user needs to sign into the extension
                        with the credentials received via email
                        <br />- After the user has signed in, client names will
                        be highlighted when using the extension within a google
                        chrome browser
                        <br />- The extension user will be able to see data in
                        the extension which shows the time of when a Company
                        name was highlighted
                        <br />- On the backend, Company Admin will be able to
                        access this data for all users
                      </P>
                    </div>
                    <Slider>
                      <Carousel showThumbs={false} autoPlay="true">
                        <div>
                          <Image src={client1} />
                        </div>
                        <div>
                          <Image src={client2} />
                        </div>
                        <div>
                          <Image src={client3} />
                        </div>
                        <div>
                          <Image src={client4} />
                        </div>
                        <div>
                          <Image src={client5} />
                        </div>
                      </Carousel>
                    </Slider>
                  </Grid>
                </TabPanel>
              </Tabs>
            </TutorialTabs>
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}
const VideoWrapper = styled.div`
  > div {
    margin-bottom: 20px;
    h2 {
      margin: 0 0 5px 0;
    }
  }
`;
const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  padding: 80px 0 120px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  &:before {
    content: "";
    height: 40px;
    width: 40px;
    background: #fff;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 99;
  }

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 100px 15px 50px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: flex-start;

  .img-tutorials{
    margin-top: 2.3rem;
  }
  &.mb-120 {
    margin-bottom: 120px;
  }

  @media only screen and (max-width: 991px) {
    display: block;

    &.mb-120 {
      margin-bottom: 60px;
    }
  }
`;
const Image = styled.img`
  max-width: 100%;

  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    &.xs-d-none {
      display: none;
    }
  }
`;
const BannerWrapper = styled.div`
  background: #fff;
  padding: 150px 0 100px 0;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 767px) {
    padding: 100px 0 50px 0;
  }
`;
const H2 = styled.h2`
  margin: 0;
  font-size: 27px;
  line-height: 32px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    height: 4px;
    width: 80px;
    background: #fe3122;
  }
`;
const P = styled.p`
  &.mb-0 {
    margin-bottom: 10px;
    color: rgba(21, 20, 23, 0.8);
  }
`;
const TutorialTabs = styled.div`
  margin-top: 70px;

  .react-tabs {
    &__tab-list {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      text-align: center;
      margin-bottom: 50px;

      @media only screen and (max-width: 1199px) {
        white-space: nowrap;
        overflow-x: auto;
        margin: 0 -30px 50px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &__tab {
      flex: 1;
      padding: 10px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid rgba(21, 20, 23, 0.1);
      transition: 300ms all ease-in-out;

      @media only screen and (max-width: 1199px) {
        padding: 10px 20px;
      }

      &--selected {
        color: rgba(45, 151, 255, 1);
        border-bottom: 3px solid rgba(45, 151, 255, 1);
      }
    }
  }
`;
const Slider = styled.div`
  .carousel .control-dots .dot {
    box-shadow: none;
    background: #b3b3b3;

    &.selected {
      background: #fe3122;
    }
  }
  .carousel .carousel-status {
    background: #fe3122;
    border-radius: 3px;
  }
`;
