import styled from "styled-components";

export const TextArea = (props) => {
    return (
        <InputWrapper>
            <Label>{props.label}</Label>
            <Textarea value={props.value} onChange={props.onChange} placeholder={props.placeholder} style={props.style} />
        </InputWrapper>
    )
}

const InputWrapper = styled.div`
    margin-bottom: 20px;
`
const Label = styled.label`
    margin: 0 0 5px;
    display: block
`
const Textarea = styled.textarea`
    padding: 15px 20px;
    background: #F7F7FB;
    border-radius: 10px;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    height: 100px;
    resize: none;

    &::-webkit-input-placeholder { 
      color: rgba(21, 20, 23, 0.3);
    }
    &:-ms-input-placeholder { 
      color: rgba(21, 20, 23, 0.3);
    }
    &::placeholder {
      color: rgba(21, 20, 23, 0.3);
    }
`