import { combineReducers } from "redux";

//Reducers
import auth from './auth/reducer'
import user from "./user/reducer";
import contact from "./contact/reducer"
import verifyEmail from "./verifyEmail/reducer";



//Define reducers

const RootReducer = combineReducers({
    auth,user,contact,verifyEmail
})

export default RootReducer;