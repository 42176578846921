import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { CenteredHeading } from "../components/atoms/heading/centered-heading";
import { ShareIcon } from "../components/common/shareicon";

export default class CookiesPolicy extends Component {
  render() {
    return (
      <>
        <Header />
        <ShareIcon />
        <ContentWrapper>
          <Container>
            <CenteredHeading largeTitle="Cookies Policy" />
            <WhiteBox>
            <P className="f-left">Last updated <Buletext>21/07/2022</Buletext></P>
              <P>
                This Cookie Policy explains how <Buletext>Adaptive Business Group</Buletext>, doing business as 
                Stoprr uses cookies and similar technologies to recognize you when you visit our
                websites at <a href="https://stoprr.com" target="_blank">https://stoprr.com</a>. It explains what these technologies are and why 
                we use them, as well as your rights to control our use of them.
              </P>
              <P>
                In some cases we may use cookies to collect personal information, or that 
                becomes personal information if we combine it with other information.
              </P>
              <P>
                What are cookies?
              </P>
              <P>
                Cookies are small data files that are placed on your computer or mobile device 
                when you visit a website. Cookies are widely used by website owners in order to 
                make their websites work, or to work more efficiently, as well as to provide 
                reporting information.
              </P>
              <P>
                Cookies set by the website owner (in this case, <Buletext>Adaptive Business Group</Buletext>) are 
                called "first party cookies". Cookies set by parties other than the website owner 
                are called "third party cookies". Third party cookies enable third party features 
                or functionality to be provided on or through the website (e.g. like advertising, 
                interactive content and analytics). The parties that set these third party cookies 
                can recognize your computer both when it visits the website in question and 
                also when it visits certain other websites
              </P>
              <P>
                Why do we use cookies?
              </P>
              <P>
                We use first and third party cookies for several reasons. Some cookies are 
                required for technical reasons in order for our Websites to operate, and we refer 
                to these as "essential" or "strictly necessary" cookies. Other cookies also enable 
                us to track and target the interests of our users to enhance the experience on 
                our Online Properties. Third parties serve cookies through our Websites for 
                advertising, analytics and other purposes. This is described in more detail below.
              </P>
              <P>
                The specific types of first and third party cookies served through our Websites 
                and the purposes they perform are described below (please note that the 
                specific cookies served may vary depending on the specific Online Properties 
                you visit):
              </P>
              <P>
                How can I control cookies?
              </P>
              <P>
                You have the right to decide whether to accept or reject cookies. You can exercise your 
                cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent
                Manager allows you to select which categories of cookies you accept or reject. Essential 
                cookies cannot be rejected as they are strictly necessary to provide you with services.
              </P>
              <P>
                The Cookie Consent Manager can be found in the notification banner and on our website. 
                If you choose to reject cookies, you may still use our website though your access to some 
                functionality and areas of our website may be restricted. You may also set or amend your 
                web browser controls to accept or refuse cookies. As the means by which you can refuse 
                cookies through your web browser controls vary from browser-to-browser, you should visit 
                your browser's help menu for more information.
              </P>
              <P>
                In addition, most advertising networks offer you a way to opt out of targeted advertising. 
                If you would like to find out more information, 
                please visit <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a> or <a href="http://www.youronlinechoices.com" target="_blank">http://www.youronlinechoices.com</a>.
              </P>
              <P>
                The specific types of first and third party cookies served through our Websites 
                and the purposes they perform are described in the table below (please note 
                that the specific cookies served may vary depending on the specific Online 
                Properties you visit):
              </P>
              <P className="underline-text">
                Essential website cookies:
              </P>
              <P>
                These cookies are strictly necessary to provide you with services available 
                through our Websites and to use some of its features, such as access to secure 
                areas.
              </P>
              <Tablewapper>
                <table>
                  <tbody>
                    <tr>
                      <td>Name:</td>
                      <td>
                        <Buletext>
                          stoprr_session
                        </Buletext>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Purpose:
                      </td>
                      <td>
                        <Buletext>
                          Laravel default session required for web authentication
                        </Buletext>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Provider:
                      </td>
                      <td>
                      <a href="http://www.stoprr.com/" target="_blank">http://www.stoprr.com/</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Service:
                      </td>
                      <td>
                        <Buletext>
                          Stoprr
                        </Buletext>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Country:
                      </td>
                      <td>
                        United Kingdom
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Type:
                      </td>
                      <td>
                        <Smalltext>Authentication cookie</Smalltext>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Expiry:
                      </td>
                      <td>
                        <Smalltext>120 mins</Smalltext>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tablewapper>
              <P>
                What about other tracking technologies, like web beacons?
              </P>
              <P>
                Cookies are not the only way to recognize or track visitors to a website. We may
                use other, similar technologies from time to time, like web beacons (sometimes 
                called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain 
                a unique identifier that enable us to recognize when someone has visited our 
                Websites or opened an e-mail including them. This allows us, for example, to 
                monitor the traffic patterns of users from one page within a website to another, 
                to deliver or communicate with cookies, to understand whether you have come 
                to the website from an online advertisement displayed on a third-party website, 
                to improve site performance, and to measure the success of e-mail marketing 
                campaigns. In many instances, these technologies are reliant on cookies to 
                function properly, and so declining cookies will impair their functioning.
              </P>
              <P>
                Do you serve targeted advertising?
              </P>
              <P>
                Third parties may serve cookies on your computer or mobile device to serve 
                advertising through our Websites. These companies may use information about 
                your visits to this and other websites in order to provide relevant 
                advertisements about goods and services that you may be interested in. They 
                may also employ technology that is used to measure the effectiveness of 
                advertisements. This can be accomplished by them using cookies or web 
                beacons to collect information about your visits to this and other sites in order 
                to provide relevant advertisements about goods and services of potential 
                interest to you. The information collected through this process does not enable 
                us or them to identify your name, contact details or other details that directly 
                identify you unless you choose to provide these.
              </P>
              <P>
                How often will you update this Cookie Policy?
              </P>
              <P>
                We may update this Cookie Policy from time to time in order to reflect, for 
                example, changes to the cookies we use or for other operational, legal or 
                regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay 
                informed about our use of cookies and related technologies.
              </P>
              <P>
                The date at the top of this Cookie Policy indicates when it was last updated.
              </P>
              <P>
                Where can I get further information?
              </P>
              <P>
                If you have any questions about our use of cookies or other technologies, please
                email us at <a href="mailto:info@adaptivebusinessgroup.com">info@adaptivebusinessgroup.com</a> or by post to:
              </P>
              <UnorderedList>
                <li>
                  <Buletext>Adaptive Business Group</Buletext>
                </li>
                <li>
                  <Buletext>Kenwood House – 1 Upper Grosvenor Road</Buletext>
                </li>
                <li>
                  <Buletext>Tunbridge Wells</Buletext>
                </li>
                <li>
                  <Buletext>United Kingdom</Buletext>
                </li>
                <li>
                  Phone: 
                  <a href="tell:+44 203 289 8653">+44 203 289 8653</a>
                </li>
              </UnorderedList>
            </WhiteBox>
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 120px 15px 70px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 970px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const WhiteBox = styled.div`
  background: #fff;
  max-width: 970px;
  padding: 30px;
  border-radius: 20px;
  margin: 0 auto;
  box-sizing: border-box;
`;
const P = styled.p`
  display:inline-block;
  width:100%;
  margin:0 0 20px;
  font-size: 16px;
  line-height: 28px;
  &.f-right {
    float: right;
  }
  &.f-left {
    float: left;
  }
  &.underline-text{
    text-decoration:underline
  }
  a{
    color:#2196f3;
  }
`;
const Buletext = styled.span`
  color:#2196f3;
`
const Tablewapper = styled.div`
  border:1px solid #777;
  padding: 15px 15px;
  margin-bottom: 20px;
  display: inline-block;
  box-sizing: border-box;
  table{
    td{
      padding:5px 10px;
      font-size:16px;
      &:first-child{
        width:100px;
        text-align: right;
      }
      a{
        color:#151417;
      }
    }
  }
`
const Smalltext = styled.span`
  font-size:14px;
`
const UnorderedList = styled.ul`
  padding:0;
  margin:0;
  li{
    width:100%;
    margin-bottom:10px;
    list-style:none;
    a{
      color:#2196f3;
      margin-left:5px;
    }
    &:last-child{
      margin:0
    }
  }
`