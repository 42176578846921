// Dependencies
import React, { useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Presentational components
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import { Input } from "../components/atoms/input/input";
import { Button } from "../components/atoms/button/button";
import { ShareIcon } from "../components/common/shareicon";
//Common Components
import { Error } from "../components/common/message";

//Assets
import signin from "../assets/images/sign-in.gif";
import styled from "styled-components";

//Actions
import auth from "../store/auth/actions";
import forgetPassword from "../store/forgetPassword/actions";

//Custom Styles
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "520px",
    borderRadius: "20px",
    padding: "40px",
    boxSizing: "border-box",
  },
  overlay: {
    backgroundColor: "rgba(21, 20, 23, 0.9)",
    zIndex: "999999",
  },
};

export default function Login() {
  //Action
  const dispatch = useDispatch();

  //Navigate Route
  //let navigate = useNavigate();

  //Use Selector
  const authState = useSelector((state) => state?.auth);

  //State For Popup
  const [showModal, setShowModal] = useState();
  const [showLoader, setShowLoader] = useState(false);

  //State for UserInfo
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  //State for forgetPassword
  const [forgetPassInfo, setForgetPassInfo] = useState({
    email: "",
  });
  //State For Error Handle
  const [error, setError] = useState({
    email: "",
    password: "",
    status: "",
    responseData: "",
  });

  //State for forgetPassword Error
  const [forgetError, setforgetError] = useState({
    email: "",
    status: "",
    responseData: "",
  });

  //Open popup onclick of forget password;
  const handleOpenModal = () => {
    setShowModal(true);
  };

  //Clese popup onclick of forget password;
  const handleCloseModal = () => {
    setShowModal(false);
  };

  //Action call on login
  const login = () => {
    setShowLoader(true);
    let valid = true;
    var validEmail = new RegExp(
      /^([a-zA-Z0-9\+\-]+)(\.[a-zA-Z0-9\+\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/
    );
    if (!userInfo.email) {
      setError((prevState) => ({
        ...prevState,
        email: "Email ID is required",
      }));
      valid = false;
    } else if (!validEmail.test(userInfo.email)) {
      setError((prevState) => ({
        ...prevState,
        email: "The email must be a valid email address.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, email: "" }));
    }
    if (!userInfo.password) {
      setError((prevState) => ({
        ...prevState,
        password: "Password is required",
      }));
      valid = false;
    } else if (userInfo.password.length < 8) {
      setError((prevState) => ({
        ...prevState,
        password: " The password must be at least 8 characters.",
      }));
      valid = false;
    } else {
      setError((prevState) => ({ ...prevState, password: "" }));
    }
    if (valid) {
      let responseData = dispatch(auth.userLogin(userInfo));
      if (responseData) {
        responseData.then((response) => {
          if (response) {
            if (response?.data?.status === 200) {
              toast.success(response?.data?.message);
              setUserInfo((prevState) => ({
                ...prevState,
                email: "",
                password: "",
              }));
              setError((prevState) => ({
                ...prevState,
                responseData: response?.data,
              }));
              setShowLoader(false);
              setTimeout(function () {
                window.location = response?.data?.data.login_link;
              }, 2000);
            } else {
              setShowLoader(false);
              setError((prevState) => ({
                ...prevState,
                responseData: response?.data,
              }));
              // toast.error(response?.data?.message);
            }
          }
        });
      }
    } else {
      setShowLoader(false);
    }
  };

  //Action call on forgetPassword

  const handlePassword = () => {
    setShowLoader(true);
    let valid = true;
    var validEmail = new RegExp(
      /^([a-zA-Z0-9\+\-]+)(\.[a-zA-Z0-9\+\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/
    );
    if (!forgetPassInfo.email) {
      setforgetError((prevState) => ({
        ...prevState,
        email: "Email ID is required",
      }));
      valid = false;
    } else if (!validEmail.test(forgetPassInfo.email)) {
      setforgetError((prevState) => ({
        ...prevState,
        email: "The email must be a valid email address.",
      }));
      valid = false;
    } else {
      setforgetError((prevState) => ({ ...prevState, email: "" }));
    }

    if (valid) {
      let responseData = dispatch(
        forgetPassword.forgetPassword(forgetPassInfo)
      );
      if (responseData) {
        responseData.then((response) => {
          if (response) {
            if (response?.status === 200) {
              toast.success(response?.data?.message);
              setForgetPassInfo((prevState) => ({ ...prevState, email: "" }));
              setforgetError((prevState) => ({
                ...prevState,
                email: "",
                status: "",
                responseData: "",
              }));
              setShowLoader(false);
              handleCloseModal();
            } else {
              setShowLoader(false);
              setforgetError((prevState) => ({
                ...prevState,
                responseData: response?.data,
              }));
              // toast.error(response?.data?.message);
            }
          }
        });
      }
    } else {
      setShowLoader(false);
    }
  };

  //Set UserName and Password on State

  const handleClick = (field, value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    field = error?.responseData?.data?.email_verification
      ? "email_verification"
      : field;
    setError((prevState) => ({
      ...prevState,
      [field]: "",
      responseData: {
        ...prevState?.responseData,
        data: {
          ...prevState?.responseData?.data,
          [field]: "",
        },
      },
    }));
  };

  //handle forget pssword on onChange
  const handleClickOnForgetPass = (field, value) => {
    setForgetPassInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setforgetError((prevState) => ({
      ...prevState,
      [field]: "",
      responseData: {
        ...prevState?.responseData,
        data: {
          ...prevState?.responseData?.data,
          [field]: "",
        },
      },
    }));
  };
  return (
    <div>
      <Header />
      <ShareIcon />
      <ContentWrapper>
        <LoginContainer>
          <div>
            <AuthWrapper>
              <LeftHeading
                smallTitle="WELCOME BACK"
                largeTitle="Sign In to stoprr"
              />
              <Input
                type="email"
                label="Email ID*"
                placeholder="youremail@gmail.com"
                value={userInfo?.email}
                onChange={(e) => handleClick("email", e.target.value)}
              />
              { error.email || error?.responseData?.message ? (
                <Error
                  className="error"
                  error={
                    error.email ||
                    error?.responseData?.data?.email ||
                    error?.responseData?.data?.email_verification
                  }
                ></Error>
              ): null }

              <Input
                type="password"
                label="Password*"
                value={userInfo?.password}
                placeholder="Password"
                onChange={(e) => handleClick("password", e.target.value)}
              />

              {error.password || error?.responseData?.message ? (
                <Error
                  className="error"
                  error={error.password || authState?.error?.data?.password}
                ></Error>
              ) : null}
              <Flex>
                <ForgotPassword onClick={handleOpenModal}>
                  Forgot Password
                </ForgotPassword>
                {/*    <Error error={authState?.error?.data?.email_verification}></Error> */}
                {/*  {error?.status === 200 ? <Success success={error?.responseMessage}></Success> :
                                    <Error error={error?.responseMessage}></Error>
                                } */}
                <div onClick={() => login()}>
                  <Button loader={showLoader} name="Login" />
                </div>
              </Flex>
            </AuthWrapper>
            <Account>
              Don’t have an account?{" "}
              <Link to="/signup">
                <strong>Sign Up</strong>
              </Link>
            </Account>
          </div>

          <Image src={signin} alt="sign in" />
        </LoginContainer>
      </ContentWrapper>
      <Footer />
      <ReactModal isOpen={showModal} style={customStyles}>
        <LeftHeading largeTitle="Forgot Your Password?" />
        <Input
          type="text"
          label="Email ID*"
          value={forgetPassInfo.email}
          placeholder="youremail@gmail.com"
          onChange={(e) => handleClickOnForgetPass("email", e.target.value)}
        />
        {forgetError.email || forgetError?.responseData?.message ? (
          <Error
            className="error"
            error={
              forgetError.email ||
              forgetError?.responseData?.data?.email ||
              forgetError?.responseData?.data?.email_verification ||
              forgetError?.responseData?.message
            }
          ></Error>
        ) : null}
        <Flex>
          <ForgotPassword onClick={handleCloseModal}>
            Back To Sign In
          </ForgotPassword>
          <div onClick={() => handlePassword()}>
            <Button loader={showLoader} name="Request Password Reset" />
          </div>
        </Flex>
      </ReactModal>
    </div>
  );
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 100px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 0 100px;
  }
  @media only screen and (max-width: 767px) {
    padding: 100px 0 70px;
  }
`;
const LoginContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 170px;
  max-width: 1320px;
  padding: 0 15px;
  box-sizing: border-box;
  align-items: center;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    grid-gap: 30px;
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;
const Image = styled.img`
  max-width: 100%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const AuthWrapper = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
`;
const ForgotPassword = styled.div`
  cursor: pointer;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;
const Account = styled.div`
  margin-top: 20px;

  strong {
    color: rgba(254, 49, 34, 1);
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;
