// Dependencies
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

// Define whether app is in production mode
const isProd = process.env.NODE_ENV === 'production';

// Define middleware list
const middlewareList = [];
middlewareList.push(thunk);

if (!isProd) {
  middlewareList.push(createLogger());
}

// Define middleware
const middleware = composeWithDevTools(applyMiddleware(...middlewareList));

// Export middleware
export default middleware;


