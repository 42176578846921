import styled from "styled-components";
import { Button } from "../atoms/button/button";
import { LeftHeading } from "../atoms/heading/left-heading";
import OurVissionImg from "../../assets/images/our-vision.gif";
import { Link } from "react-router-dom";


export const Vision = () => {
    return (
        <Wrapper>
            <Container>
                <Grid>
                    <Image src={OurVissionImg} />
                    <div>
                        <LeftHeading 
                            largeTitle="Our Vision" 
                        />
                        <List>
                        <ListItems> To put an end to recruiters contacting their own clients&apos; staff </ListItems>
                        <ListItems> To help prevent recruitment companies from losing valuable customers </ListItems>
                        <ListItems> To help recruiters feel secure when signing that &apos;non-solicitation&apos; agreement with new clients </ListItems>
                        <ListItems> To give clients confidence in the actions of their recruitment agency partners </ListItems>
                        <ListItems> We want to help bring integrity and ethics back into the recruitment industry </ListItems>
                        <ListItems> We want to solve the problem of &apos;do-not-contact&apos; lists and flawed processes </ListItems>
                        </List>
                        <Flex>
                            <Link to="/signup"><Button name="Join Now" /></Link>
                            <Link to="/tutorials"><Button style={{marginLeft: '15px', background: 'transparent', border: '1px solid #FE3122', color: '#FE3122'}} name="View Tutorial" /></Link>
                        </Flex>
                    </div>
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background: #F7F7FB;
    padding: 80px 0;
    position: relative;
    border-radius: 0 0 120px 120px;
    position: relative;
    z-index: 9;

    @media only screen and (max-width: 991px) {
        border-radius: 0 0 50px 50px;
        padding: 50px 0;
    }

    &:before{
        content: "";
        height:40px;
        width:40px;
        background:#fff;
        position:absolute;
        top:-20px;
        left:50%;
        transform:translateX(-50%) rotate(45deg);
        z-index:99;
    }
`
const Container = styled.div`
    max-width: 1320px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 0 auto;
`
const Grid = styled.div`
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
`
const Image = styled.img`
    width: 100%;
`
const List = styled.ul`
    padding: 0;
    margin:0;
    list-style: none;
`
const ListItems = styled.li`
    margin-bottom: 20px;
    padding-left: 30px;
    position: relative;

    &:before {
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: #FE3122;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 7px;
    }
`
const Flex = styled.div`
    display: inline-flex;
    margin-top: 30px;
`