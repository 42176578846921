//Services
import StoperApi from '../../services/StoperApi'


// Define namespace
const NS = '@bondboard/user';

// Define action names
export const actionTypes = {
    USER_CREATE_REQUEST: `${NS}/USER_CREATE_REQUEST`,
    USER_CREATE_SUCCESS: `${NS}/USER_CREATE_SUCCESS`,
    USER_CREATE_FAILURE: `${NS}/USER_CREATE_FAILURE`,
}


const action = (type, payload) => ({ type, payload });


const actions = {
    userCreate: (payload = {}) => {
        return async dispatch => {
            dispatch(
                action(
                    actionTypes.USER_CREATE_REQUEST,
                    {}
                )
            );

            return StoperApi.userCreate(payload).then(response => {
                dispatch(
                    action(
                        actionTypes.USER_CREATE_SUCCESS,
                        {
                            details: response
                        }
                    )
                );
                return response;
            }).catch(err => {
                dispatch(
                    action(
                        actionTypes.USER_CREATE_FAILURE,
                        err?.response?.data
                    )
                );
                return err?.response;
            })
        }
    }
}

// Export actions
export default actions;