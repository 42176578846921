import React from 'react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 class Toaster extends React.PureComponent {
    
   render(){
       return (
           <div>
               <ToastContainer
                   position="top-right"
                   autoClose={5000}
                   hideProgressBar={false}
                   newestOnTop={false}
                   closeOnClick
                   rtl={false}
                   pauseOnFocusLoss
                   draggable
                   pauseOnHover
               />
           </div>
       );
   }
}

export default Toaster