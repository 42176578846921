import styled from "styled-components";

export const StoprrWork = (props) => {
  return (
    <Wrapper>
      <Image src={props.imageURL} />
      <Footer>
        <NumberBox style={{ background: props.numberBG }}>
          <span style={{ color: props.numberColor }}>{props.number}</span>
        </NumberBox>
        <H4
          dangerouslySetInnerHTML={{
            __html: `<span>${props.headingTitle}</span>`,
          }}
        ></H4>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  border: 2px solid #fff;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 52px 54px #0000001a;
  }
`;
const Image = styled.img`
  max-width: 100%;
  height: 245px;
  width: 100%;
  object-fit: cover;

  @media only screen and (max-width: 1199px) {
    height: 350px;
  }
`;
const Footer = styled.div`
  padding: 20px 12px;
  display: flex;
  align-items: center;
`;
const NumberBox = styled.div`
  height: 37px;
  width: 37px;
  border-radius: 9px;
  line-height: 37px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  margin-right: 24px;
`;
const H4 = styled.h4`
  font-size: 18px;
  line-height: 26px;
  color: #151417;
  margin: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -12px;
    top: 50%;
    width: 1px;
    height: 80%;
    background: #ddd;
    transform: translateY(-50%);
  }
`;
