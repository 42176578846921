import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { Button } from "../components/atoms/button/button";
import { LeftHeading } from "../components/atoms/heading/left-heading";
import AboutImg from "../assets/images/about-us.gif";
import { ShareIcon } from "../components/common/shareicon";
import { Link } from "react-router-dom";

export default class About extends Component {
  render() {
    const handleToggle = () => {
      document.querySelector(".share").classList.toggle("active");
    };
    return (
      <>
        <Header />
        <ShareIcon />
        <ContentWrapper>
          <Container>
            <div>
              <LeftHeading largeTitle="Why stoprr ?" />
              <P>
                stoprr was created by recruiters for recruiters to solve the
                ongoing issue of Recruitment Consultants contacting existing
                clients’ staff on LinkedIn, job boards CV databases, and other
                platforms. The staffing market is seeing a significant increase
                in non-solicitation clauses in contracts with large penalties,
                not to mention the loss of clients.
              </P>
              <P>
                With the ever-increasing reliance on LinkedIn as a recruitment
                tool by agencies, it’s become harder than ever to stop an
                uninformed Recruiter from soliciting staff employed by one of
                their agency’s clients. With home-working, agencies working in
                multiple specialisms, and large teams, this problem has grown.
              </P>
              <P>
                ‘Do-not-contact’ lists of clients are messy and inefficient.
                stoprr provides a live, fully-managed solution that allows you
                to simply add and remove clients, and even integrates with
                Bullhorn to highlight which companies they cannot recruit from
                on LinkedIn or at least makes them stop and look into the
                company further.
              </P>
              <LeftHeading
                largeTitle="Benefits of using stoprr "
                className="mt-50"
              />
              <P>
                &#x25CF; Allows you to recruit with confidence and peace of mind
                and adhere to clients’ non-solicitation clauses
              </P>

              <P>
                &#x25CF; Allows you to advise potential clients about your
                ethical recruitment methods, which increases the success rate of
                signed recruiting contracts
              </P>

              <P>
                {" "}
                &#x25CF; Clients have become warier in increasing their
                recruitment agency pool, with solicitation of their staff being
                an increasing reason not to engage in new relationships
              </P>

              <P>
                &#x25CF; Clients would pay a higher fee to work with an agency
                with integrity that commits to not soliciting their staff
              </P>

              <P>
                &#x25CF; stoprr shows your staff that integrity and honesty are
                high on your company’s priorities.
              </P>

              <P>
                &#x25CF; Consultants knowing that their colleagues in another
                division, office or country cannot contact their clients’ staff
                gives them one less worry about losing a top account
              </P>

              <P>
                &#x25CF; Ever wondered why that client stopped working with you
                for no apparent reason? Contacting their staff would be high on
                the list, resulting in a lost relationship and thousands in lost
                fees
              </P>
              <P>Non-solicitation solved.</P>
              <Link to="/signup">
                <Button
                  name="Free Trial"
                  style={{ marginRight: 15, marginTop: 15 }}
                />
              </Link>
              <Button
                style={{ marginRight: 15, marginTop: 15 }}
                name="Share with my Agencies"
                onClick={handleToggle}
              />
            </div>
            <Image src={AboutImg} alt="about us" />
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 100px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  .mt-50 {
    margin-top: 40px;
  }
  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  box-sizing: border-box;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const Image = styled.img`
  max-width: 100%;
`;
const P = styled.p``;
