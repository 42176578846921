//Action Types
import { actionTypes } from "./action";


// Define initial state
const getInitialState = () => ({
    isFetching: false,
    isSuccess: false,
    error: ''
});


const contact = (state = getInitialState(), { type, payload }) => {
    switch (type) {
        case actionTypes.CONTACT_SEND_REQUEST:
            return {
                ...state,
                isFetching: true,
                isSuccess: false,
                error: ''
            }
       
        case actionTypes.CONTACT_SEND_SUCCESS:
            return {
                ...state,
                ...payload,
                isSuccess: true,
                isFetching: false,
                error: ''
            };
        case actionTypes.CONTACT_SEND_FAILURE:
            return {
                ...state,
                error: payload,
                isFetching: false,
                isSuccess: false,
            };


        default:
            return state;
       
    }

}

export default contact;