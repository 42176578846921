import styled from "styled-components";

export const CenteredHeading = (props) => {
    return (
        <HeadingWrapper>
            <H2>{props.largeTitle}</H2>
            <SmallText>{props.smallTitle}</SmallText>
        </HeadingWrapper>
    )
}

const HeadingWrapper = styled.div`
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 30px;
    text-align: center;

    &:after {
        content: '';
        position: absolute;
        top: calc(100% - 2px);
        left: 50%;
        transform: translateX(-50%);
        height: 6px;
        width: 80px;
        background: #FE3122;
    }

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 160px;
        background: #151417;
    }
`
const H2 = styled.h2`
    font-size: 34px;
    line-height: 41px;
    font-weight: 700;
    margin: 0 0 10px;

    @media only screen and (max-width: 767px) {
        font-size: 26px;
    }
`
const SmallText = styled.p`
    margin: 0;
`