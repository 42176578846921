import styled from "styled-components";

export const Input = (props) => {
    return (
        <InputWrapper>
            <Label>{props.label}</Label>
            <TextInput disabled={props.disabled} value={props?.value} onChange={props.onChange} type={props.type} placeholder={props.placeholder}  style={props.style}/>
        </InputWrapper>
    )
}


const InputWrapper = styled.div`
    margin-bottom: 20px;
`
const Label = styled.label`
    margin: 0 0 5px;
    display: block
`
const TextInput = styled.input`
    padding: 15px 20px;
    background: #F7F7FB;
    border-radius: 10px;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    outline: none;

    &::-webkit-input-placeholder { 
      color: rgba(21, 20, 23, 0.3);
    }
    &:-ms-input-placeholder { 
      color: rgba(21, 20, 23, 0.3);
    }
    &::placeholder {
      color: rgba(21, 20, 23, 0.3);
    }
`