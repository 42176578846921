//Services
import StoperApi from '../../services/StoperApi'


//Define NameSpace

const NS = '@stopper/auth';


//Define Action Type

export const actionTypes = {
    LOGIN_REQUEST: `${NS}/LOGIN_REQUEST`,
    LOGIN_SUCCESS: `${NS}/LOGIN_SUCCESS`,
    LOGIN_FAILURE: `${NS}/LOGIN_FAILURE`,

}

const action = (type, payload) => ({ type, payload });

//Define Actions

const actions = {
    userLogin: (payload = {}) => {
        return async dispatch => {
            dispatch(
                action(
                    actionTypes.LOGIN_REQUEST,
                    {}
                )
            );

            return StoperApi
                .userLogin(payload)
                .then(response => {
                    sessionStorage.setItem('id_token', response.data.token);
                    dispatch(action(
                        actionTypes.LOGIN_SUCCESS,
                        response?.data
                    ));
                    return response;
                })
                .catch(err => {
                    dispatch(action(
                        actionTypes.LOGIN_FAILURE,
                        {
                          error:err?.response?.data
                        }
                    ));
                    return err?.response;
                }
                );


        }
    }
}

// Export actions
export default actions;