import styled from "styled-components";
import { Button } from "../atoms/button/button";
import { BsMouse } from "react-icons/bs";
import ImgGirl from "../../assets/images/banner-girl.gif";
import BannerImg from "../../assets/images/banner-extension.gif";
import BannerBg from "../../assets/images/banner-bg.jpg";
import CloudImg from "../../assets/images/cloud.svg";
import { Link } from "react-router-dom";

export const Banner = () => {
  return (
    <StyledBanner>
      <Cloud />
      <Cloud className="cloud1" />
      <Cloud className="cloud2" />
      <Cloud className="cloud3" />
      <Link className="img-left" to="/subscription">
        <img src={BannerImg} alt="" />
      </Link>
      <Container>
        <H1>
          Stop contacting
          <br />
          your clients&apos; staff
        </H1>
        <ButtonWrapper>
        <Link to="/signup">
          <Button
            style={{
              background: "#FEDA00",
              color: "#151417",
              fontSize: "22px",
              borderRadius: "40px",
              position: "relative",
            }}
            name="Free Trial"
          />
        </Link>
        <Link to="/tutorials">
          <Button
            style={{
              background: "#00d800",
              color: "#151417",
              fontSize: "22px",
              borderRadius: "40px",
              position: "relative",
            }}
            name="Watch the Tutorial"
          />
        </Link>
        </ButtonWrapper>
      </Container>
      <MouseIcon>
        <BsMouse size={35} />
      </MouseIcon>
      <ImageGirl src={ImgGirl} alt="" />
    </StyledBanner>
  );
};
const ButtonWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  gap:1rem;
  justify-content: center;
  z-index: 9;
  margin-top:40px
`
const StyledBanner = styled.div`
  /* background: #2996fc url(${BannerBg}); */
  background: #2996fc;
  background-size: contain;
  height: 100vh;
  position: relative;
  overflow: hidden;
  position: relative;
  z-index: 9;

  .img-left {
    position: absolute;
    left: 5%;
    top: 60%;
    transform: translateY(-50%);
    max-height: 70%;
    z-index: 9;

    img {
      max-width: 70%;
    }

    @media only screen and (max-width: 1199px) {
      max-height: 60%;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`;
const Container = styled.div`
  max-width: 1320px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
`;
const H1 = styled.h1`
  font-size: 70px;
  color: #ffffff;
  line-height: 70px;
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 99;

  @media only screen and (max-width: 1199px) {
    font-size: 60px;
    line-height: 64px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 50px;
    line-height: 50px;
  }
`;
const MouseIcon = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 170px;
  height: 170px;
  background: #f7f7fb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 30px;
`;
const ImageGirl = styled.img`
  position: absolute;
  right: 9%;
  bottom: 0;
  max-height: 80%;

  @media only screen and (max-width: 1199px) {
    right: 0;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
const Cloud = styled.div`
  position: absolute;
  top: 110px;
  left: 200px;
  background: url(${CloudImg}) no-repeat;
  background-size: contain;
  height: 100px;
  width: 150px;

  @media only screen and (max-width: 991px) {
    display: none;
  }

  &.cloud1 {
    top: 150px;
    left: 60%;
  }
  &.cloud2 {
    bottom: 60px;
    left: 45%;
    top: auto;
  }
  &.cloud3 {
    top: 45%;
    left: auto;
    right: 0;
    width: 100px;
  }
`;
