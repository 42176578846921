import styled from "styled-components";
import { StoprrWork } from "../molecules/stoprr-work-home";
import { CenteredHeading } from "../atoms/heading/centered-heading";
import addStoprrExtension from "../../assets/images/add-stoprr-extension.gif";
import IntegrateWithCRM from "../../assets/images/Integrate-with-CRM.gif";
import ClientsStaffIdentified from "../../assets/images/Clients-Staff-Identified.gif";
import RecruitmentWithConfidence from "../../assets/images/superman.gif";

export const Work = () => {
  return (
    <Wrapper>
      <Container>
        <CenteredHeading
          smallTitle="stoprr is the only tool that helps you prevent your recruitment agency teams from soliciting your clients&apos; staff. It's a simple process…"
          largeTitle="How stoprr works"
        />
        <Grid>
          <StoprrWork
            number="1"
            numberBG="#FE3122"
            headingTitle="Add stoprr<br/>Extension"
            imageURL={addStoprrExtension}
          />
          <StoprrWork
            number="2"
            numberBG="#04BB00"
            numberColor="#2B372A"
            headingTitle="Upload Client <br/>Lists"
            imageURL={IntegrateWithCRM}
          />
          <StoprrWork
            number="3"
            numberBG="#FEDB00"
            numberColor="#2B372A"
            headingTitle="Clients' Staff<br/>Identified"
            imageURL={ClientsStaffIdentified}
          />
          <StoprrWork
            number="4"
            numberBG="#2D97FF"
            headingTitle="Recruit with<br/>Confidence"
            imageURL={RecruitmentWithConfidence}
          />
        </Grid>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f7f7fb;
  padding: 80px 0;
  position: relative;
  z-index: 9;
`;
const Container = styled.div`
  max-width: 1320px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media only screen and (max-width: 1199px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;
