import styled from "styled-components";

export const FeatureItems = (props) => {
    return (
        <Wrapper style={{background: props.bgColor}}>
            <Image src={props.imageURL} />
            <H4>{props.heading}</H4>
            <Text>{props.paragrap}</Text>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background: #FEFBE5;
    border-radius: 20px;
    border: 1px solid #1514171A;
    padding: 30px;
    text-align: center;
`
const Image = styled.img`
    max-height: 243px;
    margin: 0 auto;
    object-fit: contain;
`
const H4 = styled.h4`
    font-size: 21px;
    line-height: 25px;
    font-weight: 700;
    margin: 45px 0 15px;
`
const Text = styled.p`
`