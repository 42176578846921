import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../components/common/header";
import { Footer } from "../components/common/footer";
import { CenteredHeading } from "../components/atoms/heading/centered-heading";
import { ShareIcon } from "../components/common/shareicon";
export default class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Header />
        <ShareIcon />
        <ContentWrapper>
          <Container>
            <CenteredHeading largeTitle="Privacy Policy" />
            <WhiteBox>
              <p>
                Last updated 1<sup>st</sup> June 2022.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                Users&apos; privacy is important
                to&nbsp;Stoprr&nbsp;(&quot;we,&quot; &quot;us,&quot; or
                &quot;our&quot;), and we take it very seriously. As a visitor to
                our website,&nbsp;
                <u>
                  <a href="https://stoprr.com/">https://stoprr.com</a>
                </u>
                <span size="3">&nbsp;</span>you agree to the terms and
                conditions of this Privacy Policy, which explains how we
                collect, use, disclose and secure your personal information. If
                you have any questions or concerns, please review this privacy
                statement. Do not use this site if you do not agree with the
                terms of this privacy policy.
              </p>
              <p>
                <br />
              </p>
              <p>
                Any modifications to this Privacy Policy may be made at any time
                and for any reason. If this Privacy Policy&apos;s &quot;Last
                Updated&quot; date changes, we&apos;ll let you know. It is your
                responsibility to review the Privacy Policy on a regular basis
                to ensure that you are aware of any changes or amendments to the
                Policy.
              </p>
              <p>
                <br />
              </p>
              <p>
                You should review our Privacy Policy on a regular basis to
                remain up to speed on any changes. By continuing to use the Site
                after the date the amended Privacy Policy is posted, you are
                assumed to be aware of, subject to, and have accepted the
                changes in the revised Privacy Policy.&nbsp;
              </p>
              <h1>
                <strong>COLLECTION OF YOUR INFORMATION</strong>
              </h1>
              <p>
                In a variety of methods, we may obtain information about you
                from you. We may gather the following types of information:
              </p>
              <p>
                <br />
              </p>
              <p>
                When you register with the Site or participate in various
                activities related to the Site, such as online chat and message
                boards, you voluntarily provide us with personally identifiable
                information, such as your name, shipping address, email address,
                and telephone number, as well as demographic information, such
                as your age, gender, hometown, and interests. However, if you do
                not give us with any personal information, you may not be able
                to use any of the Site&apos;s services.
              </p>
              <p>
                <br />
              </p>
              <p>
                Our servers automatically collect information such your IP
                address, your browser type, your operating system, your access
                times, and the pages you have seen just before and after
                accessing the Site.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                When you purchase, order, return, exchange, or seek information
                about our services via the Site, we may collect financial
                information, such as data linked to your payment method (e.g.
                valid credit card number, card brand, expiration date). We save
                only a little amount of financial data, if any at all. Stripe is
                our payment processor and you may study their privacy policies
                and contact them directly if you have any questions about how
                your financial information is handled by them.
              </p>
              <p>
                <br />
              </p>
              <p>
                Your name, your social network username, location, gender, birth
                date, email address, profile picture, and public data for
                contacts, if you choose to link your account to social networks
                such as Facebook, Instagram, or Twitter.
              </p>
              <p>
                <br />
              </p>
              <p>
                If you use the Site using a mobile device, we may collect
                information such as your mobile device&apos;s ID, model, and
                manufacturer, as well as information about where your device is
                located.
              </p>
              <p>
                <br />
              </p>
              <p>
                Connecting your account to another service and granting the site
                permission to access third-party data, such as personal
                information or network contacts, is referred to as
                &quot;third-party data.&quot;
              </p>
              <p>
                <br />
              </p>
              <p>
                When you participate in competitions, sweepstakes, or surveys,
                you may be asked to provide your name, email address, and other
                personal information.
              </p>
              <h1>
                <strong>USE OF YOUR INFORMATION&nbsp;</strong>
              </h1>
              <p>
                Having accurate information about you permits us to provide you
                with a smooth, efficient, and customized experience.
              </p>
              <p>
                Specifically, we may use information collected about you via the
                Site to:&nbsp;
              </p>
              <p>
                <br />
              </p>
              <ul>
                <li>
                  <p>Create and manage your account.</p>
                </li>
                <li>
                  <p>
                    Compile anonymous statistical data and analysis for use
                    internally or with third parties.&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Deliver targeted advertising, coupons, newsletters, and
                    other information regarding promotions and the Site to
                    you.&nbsp;
                  </p>
                </li>
                <li>
                  <p>Email you regarding your account or order.</p>
                </li>
                <li>
                  <p>Enable user-to-user communications.</p>
                </li>
                <li>
                  <p>
                    Fulfill and manage purchases, orders, payments, and other
                    transactions related to the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Generate a personal profile about you to make future visits
                    to the Site more personalized.
                  </p>
                </li>
                <li>
                  <p>Increase the efficiency and operation of the Site.</p>
                </li>
                <li>
                  <p>
                    Monitor and analyze usage and trends to improve your
                    experience with the Site.
                  </p>
                </li>
                <li>
                  <p>Notify you of updates to the Site s.</p>
                </li>
                <li>
                  <p>
                    Offer new products, services, and/or recommendations to you.
                  </p>
                </li>
                <li>
                  <p>Perform other business activities as needed.</p>
                </li>
                <li>
                  <p>
                    Prevent fraudulent transactions, monitor against theft, and
                    protect against criminal activity.
                  </p>
                </li>
                <li>
                  <p>Process payments and refunds.</p>
                </li>
                <li>
                  <p>
                    Request feedback and contact you about your use of the
                    Site.&nbsp;
                  </p>
                </li>
                <li>
                  <p>Resolve disputes and troubleshoot problems.</p>
                </li>
                <li>
                  <p>Respond to product and customer service requests.</p>
                </li>
                <li>
                  <p>Send you a newsletter.</p>
                </li>
              </ul>
              <h1>
                <strong>DISCLOSURE OF YOUR INFORMATION</strong>
              </h1>
              <p>
                Your personal information is never shared with anyone else.
                However, we may share your personal information with third-party
                service providers who help us provide the Services, such as
                shipping, payment processing, marketing and advertising, email,
                text, and other communications, applications, sales, business
                and inventory support, photography, and website, software,
                platform, cloud, data storage, and other technical services. We
                may also share your personal information to: (1) comply with
                federal, state, or local laws; (2) comply with a civil,
                criminal, or regulatory inquiry, investigation, subpoena, or
                summons by federal, state, or local authorities; (3) cooperate
                with law enforcement agencies in the investigation of conduct or
                activity that we reasonably and in good faith believe may
                violate federal, state, or local laws; or (4) exercise or defend
                legal claims. We may also share non-personal information with
                third parties who provide and run Services on our behalf or
                assist us in analyzing how our Services are used, as long as the
                information has been deidentified or aggregated.
              </p>
              <p>
                We are not responsible for the actions of third parties with
                whom you share personal or sensitive data, and we have no
                authority to manage or control third-party solicitations. If you
                no longer wish to receive correspondence, emails or other
                communications from third parties, you are responsible for
                contacting the third party directly.
              </p>
              <h1>
                <strong>TRACKING TECHNOLOGIES</strong>
              </h1>
              <h2>
                <strong>Cookies and Web Beacons</strong>
              </h2>
              <p>
                There are a number of technologies that we use on the Site to
                enhance your experience, including cookies, web beacons,
                tracking pixel, and other technologies. Tracking technology is
                not used to collect personal information when you visit the
                Site. Cookies are accepted by default in most browsers. However,
                you should be aware that deleting or rejecting cookies might
                have an impact on the Site&apos;s availability and functioning.
                Web beacons can&apos;t be turned off. Although cookies can be
                rendered useless by rejecting all cookies or updating your
                browser&apos;s settings to inform you each time a cookie is
                offered, you can accept or decline cookies on an individual
                basis by adjusting these options.
              </p>
              <p>
                <br />
              </p>
              <p>
                In order to provide you with a better online experience, we
                employ a variety of tracking technologies, including as cookies,
                web beacons, tracking pixels, and others.
              </p>
              <p>
                You should be aware that getting a new computer, installing a
                new browser, upgrading an existing browser, or erasing or
                otherwise altering your browser&rsquo;s cookies files may also
                clear certain opt-out cookies, plug-ins, or settings.
              </p>
              <h1>
                <strong>SECURITY OF YOUR INFORMATION</strong>
              </h1>
              <p>
                Personal information is protected by administrative,
                technological, and physical security methods. Please be aware
                that, despite our best efforts, no security measures are
                flawless or impenetrable, and no mode of data transfer can be
                guaranteed against any interception or other sort of misuse. Any
                information that is posted online can be accessed and misused by
                anybody who has access to the internet. To the extent that you
                give us personal information, we cannot guarantee that it will
                be completely secure.
              </p>
              <h1>
                <strong>POLICY FOR CHILDREN</strong>
              </h1>
              <p>
                It is our policy not to intentionally collect or market to
                anybody under the age of 18 without their express consent.
                Please use the contact information below if you have any
                information about minors under the age of 18 that we may have
                acquired.
              </p>
              <p>
                <strong>CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</strong>
              </p>
              <p>
                If you desire to keep your online activity private, you may
                activate a &quot;Do Not Track&quot; (DNT) option or setting in
                most web browsers and some mobile operating systems. There is
                currently no standard technology for identifying and applying
                DNT signals. Thus, we do not respond to browser DNT signals or
                any other technology that automatically expresses your
                preference not to be followed on the Internet at this time. As
                soon as a new standard for online monitoring is established, we
                will update our Privacy Policy to reflect that change.
              </p>
              <h1>
                <strong>OPTIONS REGARDING YOUR INFORMATION</strong>
              </h1>
              <h2>
                <strong>Account Information</strong>
              </h2>
              <p>
                You may access and modify your account information at any time
                by using the methods listed below:
              </p>
              <ul>
                <li>
                  <p>
                    Logging into your account settings and making the necessary
                    changes&nbsp;
                  </p>
                </li>
                <li>
                  <p>Contacting us via the methods listed below.</p>
                </li>
              </ul>
              <p>
                We will deactivate or remove your account and all associated
                information at your request. To prevent fraud, troubleshoot
                problems, aid in any investigations, enforce our Terms of Use,
                and/or comply with legal requirements, we may keep some
                information in our files for the time being.
              </p>
              <h2>
                <strong>Emails and Communications</strong>
              </h2>
              <p>
                By noting your choices when you establish an account with the
                Site or by logging into your account settings and changing your
                options, you can no longer receive correspondence, emails, or
                other communications from us.
              </p>
              <p>
                You can get in touch with us by utilizing the details provided
                below.
              </p>
              <p>
                You must contact the third party directly if you no longer
                desire to receive correspondence, emails, or other
                communications from them.
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>
                  LEGAL BASIS FOR PROCESSING PERSONAL DATA UNDER GDPR
                </strong>
              </p>
              <p>
                <br />
              </p>
              <p>
                We may process Personal Data under the following conditions:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Consent:</strong> You have given Your consent for
                    processing Personal Data for one or more specific purposes.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Performance of a contract:</strong> Provision of
                    Personal Data is necessary for the performance of an
                    agreement with You and/or for any pre-contractual
                    obligations thereof.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Legal obligations:</strong> Processing Personal Data
                    is necessary for compliance with a legal obligation to which
                    we are subject.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Vital interests:</strong> Processing Personal Data
                    is necessary in order to protect Your vital interests or of
                    another natural person.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Public interests:</strong> Processing Personal Data
                    is related to a task that is carried out in the public
                    interest.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Legitimate interests:</strong> Processing Personal
                    Data is necessary for the purposes of the legitimate
                    interests pursued by us.
                  </p>
                </li>
              </ul>
              <p>
                In any case, we will gladly help to clarify the specific legal
                basis that applies to the processing, and in particular whether
                the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a
                contract.
              </p>
              <p>
                <br />
              </p>
              <p>
                <strong>Your Rights under the GDPR</strong>
              </p>
              <p>
                We undertake to respect the confidentiality of Your Personal
                Data and guarantee You can exercise Your rights.
              </p>
              <p>
                You have the right under this Privacy Policy, and by law if You
                are within the EU, to:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Request access to Your Personal Data.</strong> The
                    right to access, update or delete the information We have on
                    You. Whenever made possible, you can access, update or
                    request deletion of Your Personal Data directly within Your
                    account settings section. If you are unable to perform these
                    actions yourself, please contact Us to assist You. This also
                    enables You to receive a copy of the Personal Data We hold
                    about You.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Request correction of the Personal Data that We hold about
                      You.
                    </strong>{" "}
                    You have the right to have any incomplete or inaccurate
                    information We hold about You corrected.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Object to processing of Your Personal Data.</strong>{" "}
                    This right exists where We are relying on a legitimate
                    interest as the legal basis for Our processing and there is
                    something about Your particular situation, which makes You
                    want to object to our processing of Your Personal Data on
                    this ground. You also have the right to object where We are
                    processing Your Personal Data for direct marketing purposes.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Request erasure of Your Personal Data.</strong> You
                    have the right to ask Us to delete or remove Personal Data
                    when there is no good reason for Us to continue processing
                    it.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Request the transfer of Your Personal Data.</strong>{" "}
                    We will provide to You, or to a third-party You have chosen,
                    Your Personal Data in a structured, commonly used,
                    machine-readable format. Please note that this right only
                    applies to automated information which You initially
                    provided consent for Us to use or where We used the
                    information to perform a contract with You.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Withdraw Your consent.</strong> You have the right
                    to withdraw Your consent on using your Personal Data. If You
                    withdraw Your consent, We may not be able to provide You
                    with access to certain specific functionalities of the
                    Service.
                  </p>
                </li>
              </ul>
              <p>
                <br />
              </p>
              <p>
                <strong>Exercising of Your GDPR Data Protection Rights</strong>
              </p>
              <p>
                You may exercise Your rights of access, rectification,
                cancellation and opposition by contacting Us. Please note that
                we may ask You to verify Your identity before responding to such
                requests. If You make a request, We will try our best to respond
                to You as soon as possible.
              </p>
              <p>
                You have the right to complain to a Data Protection Authority
                about Our collection and use of Your Personal Data. For more
                information, if You are in the European Economic Area (EEA),
                please contact Your local data protection authority in the EEA.
              </p>
              <p>
                <br />
              </p>
              <h1>
                <strong>CONTACT US</strong>
              </h1>
              <p>
                If you have questions or comments about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                <br />
              </p>
              <p>Stoprr</p>
              <p>[City, State Zip]</p>
              <p>[Phone Number]</p>
              <p>[Fax Number]</p>
              <p>[Email]&nbsp;</p>
              <p>
                <br />
              </p>
            </WhiteBox>
            <P className="f-right">Updated Febuary 17, 2021</P>
          </Container>
        </ContentWrapper>
        <Footer />
      </>
    );
  }
}

const ContentWrapper = styled.div`
  background: #f7f7fb;
  border-radius: 0 0 120px 120px;
  min-height: calc(100vh - 84px);
  padding: 120px 15px 70px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 991px) {
    border-radius: 0 0 50px 50px;
    padding: 150px 15px 100px;
  }

  @media only screen and (max-width: 767px) {
    border-radius: 0 0 50px 50px;
    padding: 70px 15px 70px;
  }
`;
const Container = styled.div`
  max-width: 970px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;
const WhiteBox = styled.div`
  background: #fff;
  max-width: 970px;
  padding: 30px;
  border-radius: 20px;
  margin: 0 auto;
  box-sizing: border-box;
`;
const P = styled.p`
  &.f-right {
    float: right;
  }
`;
